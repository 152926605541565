export default {
  FILTER_STATUS: { // 查詢教室狀態
    // CLOUD_CLASSROOM: 'cloud_classroom', // 雲端教室
    CLOUD_CLASSROOM: 'jr_student_index', // 雲端教室
    RECORD: 'record', // 課程紀錄
  },
  CLOUD_TABLE: {
    REFRESH_TIME: 30000, // TABLE 刷新時間(ms)
  },
  ENTER_CLASS_ROOM_TYPE: {
    ALLOW: 'allow', // 允許進入教室
    OVER_TIME: 'over-time', // 超過進入教室時間
    NOT_YET: 'not-yet', // 尚未能進入教室
  },
  BOOK_CLASS: {
    TYPE: 'junior_normal',
    ONE_ON_ONE: 1, // wuwow一對一訂課
    ONE_ON_TREE: 2, // wuwow一對三訂課
    ONE_ON_ONE_DEMO: 3, // 1對1 DEMO
    SHORT_DEMO: 5, // 短Demo
    LECTURE: 6, // 百家書院
    CLASS_TYPE_NORMAL: 11, // 1on1 Junior家教班
    CLASS_TYPE_SPECIAL: 12, // 1on1 Junior特殊家教班
    CLASS_TYPE_SPECIFY_MATERIAL: 13, // 1on1 Junior自備教材家教班
    JUNIOR_ONE_ON_ONE_DEMO: 16, // JR 1對1 DEMO
    REALTIME_WUWOW_DEMO: 17, // 即時WUWOW DEMO
    REALTIME_JUNIOR_DEMO: 18, // 即時JR DEMO
  },
  CLASSROOM_STATUS: {
    NORMAL: 0,
    STUDENT_CANCELLED: 1,
    PROBLEM: 2,
    DEMO_PAID: 3, // DEMO 未開始 若正常開始會變成 normal
    TEACHER_DELETE: 4,
  },
  CONSULTANT_STATUS: {
    NO_SHOW: 0,
    SHOW: 1,
    LATE: 2,
    TARDY: 3,
    PROBLEM_NO_PAID: 4,
    PROBLEM_PAID: 5,
  },
  VIP_STATUS: {
    NO_SHOW: 0,
    SHOW: 1,
    PROBLEM: 2,
    RETURNED: 3,
    CANCELLED: 4,
    DEMO_UNCONFIRM: 5,
    DEMO_CONFIRMED: 6,
  },
};
