<template lang="pug">
  div
    p.txt-system-time
      font-awesome-icon.clock-icon(:icon="['far', 'clock']" class="fa-2x")
      | &nbsp;&nbsp;
      server-time
</template>

<script type="text/javascript">
import serverTime from './serveTime';

export default {
  name: 'ServerTimeClock',

  components: {
    serverTime,
  },
};
</script>
