const bookClassLang = {
  messages: {
    tw: {
      bookClassTable: '訂課表',
      setBookClassAlert: '設定訂課提醒',
      cilckDateToBookClass: '點擊日期訂課',
      countBookClass: '本週已使用',
      countBookClassBbhind: '每週建議提升堂數',
      class: '堂',
    },
    en: {
      bookClassTable: '',
      setBookClassAlert: '',
      cilckDateToBookClass: '',
      countBookClass: '',
      countBookClassBbhind: '',
      class: '',
    },
  },
};

export {
  bookClassLang
};
