<template lang="pug">
  b-modal(v-if="privateBookInfo" v-model="show" title='您已經完成客製化課程需求的設定' ok-only centered @change="close" hide-footer header-bg-variant="primary")
    .d-flex.justify-content-center
      .px-2
        .my-1 課程時間：
          span {{privateBookInfo.classApplication.classTime}}
        .my-1 顧問：
          span.badge.badge-secondary(v-if="privateBookInfo.teacher.chineseName === '' && privateBookInfo.teacher.englishName === ''") 未指定
          span(v-else)
            | {{ `${privateBookInfo.teacher.englishName !== '' ? privateBookInfo.teacher.englishName : privateBookInfo.teacher.chineseName}` }}
        .my-1 教材：
          a(:href="privateBookInfo.material.file") 點我下載
        .my-1 備註： {{ privateBookInfo.classApplication.note }}
</template>

<script type="text/javascript">
import { v4 } from 'uuid';

import modalMixin from '@/mixins/modal';

export const uuid = v4();

export default {
  name: 'PrivateBookClassModal',

  mixins: [
    modalMixin,
  ],

  props: {
    privateBookInfo: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },

  data() {
    return {
    };
  },

  computed: {
  },

  watch: {},

  created() {
    this.initModal(uuid, {
      status: false,
      payload: null,
    });
  },

  methods: {

    test() {
      console.log('test');
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .modal-content {
  // h4 {
  //   margin-top: -48px;
  // }
  .modal-header {
    align-items: center;
    h5 {
      font-size: 20px;
    }
  }
  .btn {
    margin: 0 auto;
    width: 40%;
    border: 5px;
  }
  .close {
    background-color: #D05959;
    margin: 2px;
    border-radius: 8px;
    padding: 6px 10px;
    color: white;
    opacity: 1;
    z-index: 1;
  }
  .modal-body {
    font-size: 16px;
    padding: 30px 0;
  }

  @media screen and ( max-width: 414px ){
    .modal-header {
      h5 {
        font-size: 18px;
      }
    }
  }
}
</style>
