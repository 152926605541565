var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticClass: "application-modal",
    attrs: {
      "id": "classApplicationModal",
      "size": "lg",
      "centered": "",
      "no-close-on-backdrop": "",
      "hide-footer": ""
    },
    on: {
      "hidden": _vm.cancelBooking
    }
  }, [_c('b-container', {
    staticClass: "bv-example-row"
  }, [_c('b-row', {
    staticClass: "text-center application-modal-title"
  }, [_c('b-col', [_c('p', {
    staticClass: "title-week"
  }, [_vm._v(_vm._s(_vm.getWeekDay))]), _c('p', {
    staticClass: "title-date"
  }, [_vm._v(_vm._s(_vm.bookingDate.bookingTitle))])])], 1), _c('br'), _c('b-form-group', [_c('b-nav', {
    staticClass: "book-type",
    attrs: {
      "fill": ""
    }
  }, [_c('b-nav-item', {
    class: [_vm.showType === _vm.bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.BOOKING ? 'buttonSelected' : 'buttonCancel'],
    on: {
      "click": function ($event) {
        _vm.showType = _vm.bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.BOOKING;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('bookClass')))]), _c('b-nav-item', {
    class: [_vm.showType === _vm.bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.TODAY_BOOKING ? 'buttonSelected' : 'buttonCancel'],
    on: {
      "click": function ($event) {
        _vm.showType = _vm.bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.TODAY_BOOKING;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('todayBooking')))])], 1)], 1), _c('br'), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType === _vm.bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.BOOKING,
      expression: "showType === bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.BOOKING"
    }]
  }, [_c('div', {
    staticClass: "step-bar"
  }, _vm._l(_vm.step.enum, function (item, index) {
    return _c('div', {
      staticClass: "step",
      class: {
        active: _vm.step.active == index + 1
      }
    }, [_c('div', {
      staticClass: "step-circle"
    }, [_vm._v(_vm._s(index + 1))]), _c('p', [_vm._v(_vm._s(item))])]);
  }), 0), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.step.active == 1 ? _c('section', {
    key: "1"
  }, [_c('b-form-group', [_c('div', {
    staticClass: "panel-clsapc-input"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('span', {
    staticClass: "input-group-text"
  }, [_vm._v(_vm._s(_vm.$t('time')))])]), _c('b-form-select', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "required": "",
      "name": "time",
      "options": _vm.class_time
    },
    on: {
      "change": _vm.onChangeFilter
    },
    model: {
      value: _vm.filter.time,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "time", $$v);
      },
      expression: "filter.time"
    }
  }), _c('b-form-select', {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: 'required',
      expression: "'required'"
    }],
    attrs: {
      "required": "",
      "name": "class_time",
      "options": _vm.timelistOptions
    },
    on: {
      "change": _vm.onChangeFilter
    },
    model: {
      value: _vm.filter.class_time,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "class_time", $$v);
      },
      expression: "filter.class_time"
    }
  }, [_c('template', {
    slot: "first"
  }, [_c('option', {
    attrs: {
      "disabled": "disabled"
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(_vm._s(_vm.$t('pleaseSelect')) + _vm._s(_vm.$t('time')))])])], 2), _c('br')], 1)])]), _vm._l(_vm.alert_messages, function (message, index) {
    return _c('div', {
      key: index
    }, [_c('b-alert', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: message.text,
        expression: "message.text"
      }],
      staticClass: "book-res",
      attrs: {
        "show": true,
        "variant": message.alert ? 'success' : 'danger'
      }
    }, [_vm._v(_vm._s(index + 1) + ". " + _vm._s(message.text))])], 1);
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.enablePrivateConsultantBooking,
      expression: "enablePrivateConsultantBooking"
    }]
  }, [_c('b-alert', {
    staticClass: "pb-alert text-center",
    attrs: {
      "show": ""
    }
  }, [_vm._v("如欲指定顧問，或預約客製化課程(自備教材)"), _c('br'), _vm._v("，請於課程"), _c('span', {
    staticClass: "alertText"
  }, [_vm._v("72" + _vm._s(_vm.$t('hour')))]), _vm._v(_vm._s(_vm.$t('before')) + _vm._s(_vm.$t('booking')))])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.enablePrivateBooking,
      expression: "enablePrivateBooking"
    }]
  }, [_c('private-booking', {
    key: _vm.filter.class_type.value,
    attrs: {
      "data_resource": "application",
      "checkPrivateBookingTime": _vm.checkPrivateBookingTime,
      "topicList": _vm.topicList,
      "consultantList": _vm.consultantList,
      "classType": _vm.filter.class_type.value,
      "classOptions": _vm.classOptions,
      "customized-classroom-rule": _vm.customizedClassroomRule,
      "booking_type": 'new'
    },
    on: {
      "custom-application": _vm.oneOnOneData
    }
  })], 1)], 2) : _vm._e(), _vm.step.active == 2 ? _c('section', {
    key: "2"
  }, [_c('b-list-group', [_c('b-list-group-item', [_vm._v("日期：" + _vm._s(_vm.formatDate(_vm.bookingDate.bookingDateValue)))]), _c('b-list-group-item', [_vm._v("時段：" + _vm._s(_vm.findOption(_vm.class_time, 'value', _vm.filter.time, 'text')))]), _c('b-list-group-item', [_vm._v("時間：" + _vm._s(_vm.formatTime(_vm.filter.class_time)))]), _c('b-list-group-item', [_vm._v("課程類型：" + _vm._s(_vm.findOption(_vm.class_type, 'value.value', _vm.filter.class_type.value, 'text')))]), _c('b-list-group-item', [_vm._v("指定顧問：" + _vm._s(_vm.classOptions.consultant ? _vm.classOptions.consultant.english_name : '--'))]), _vm.classOptions.isUploadMaterial ? _c('b-list-group-item', [_vm._v("指定自備教材：" + _vm._s(_vm.classOptions.material_file ? _vm.classOptions.material_file.name : '--'))]) : _vm._e()], 1), _vm._l(_vm.alert_messages, function (message, index) {
    return _c('div', {
      key: index,
      staticClass: "mt-2"
    }, [_c('b-alert', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: message.text,
        expression: "message.text"
      }],
      staticClass: "book-res",
      attrs: {
        "show": true,
        "variant": message.alert ? 'success' : 'danger'
      }
    }, [_vm._v(_vm._s(index + 1) + ". " + _vm._s(message.text))])], 1);
  }), _c('b-alert', {
    staticClass: "mt-3",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('p', [_vm._v("提醒您 :")]), _c('ol', [_c('li', [_vm._v("若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。")]), !_vm.customizedClassroomRule.new_rule && _vm.classOptions.material_file ? _c('li', [_vm._v("【客製化課程】：上傳自備教材並可同時指定顧問，需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消課程。")]) : _vm._e(), _vm.customizedClassroomRule.new_rule && (_vm.classOptions.consultant || _vm.classOptions.material_file) ? _c('li', {
    staticClass: "text-danger time-alert"
  }, [_vm._v(_vm._s(_vm.timeAlert()))]) : _vm._e()])])], 2) : _vm._e()])], 1), _vm.showType === _vm.bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.TODAY_BOOKING ? _c('div', [_c('today-booking', {
    attrs: {
      "todayClassApplication": _vm.bookingDate.classApllication,
      "new-rule": _vm.customizedClassroomRule.new_rule
    },
    on: {
      "bookingClassChange": _vm.bookingClassChange
    }
  })], 1) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showType !== _vm.bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.TODAY_BOOKING,
      expression: "showType !== bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.TODAY_BOOKING"
    }],
    staticClass: "modal-footer mt-3"
  }, [_vm.step.active == 1 ? _c('b-button', {
    key: "next",
    attrs: {
      "variant": "primary",
      "disabled": !_vm.enableSubmit,
      "block": ""
    },
    on: {
      "click": function ($event) {
        _vm.step.active = 2;
      }
    }
  }, [_vm._v("下一步 / 確認課程資訊")]) : _vm._e(), _vm.step.active == 2 && !_vm.alert_messages.length ? _c('b-button', {
    key: "previous",
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function ($event) {
        _vm.step.active = 1;
      }
    }
  }, [_vm._v("上一步")]) : _vm._e(), _vm.step.active == 2 && !_vm.alert_messages.length ? _c('b-button', {
    key: "success",
    attrs: {
      "variant": "success",
      "block": ""
    },
    on: {
      "click": function ($event) {
        return _vm.onClassSubmit();
      }
    }
  }, [_vm._v("確認送出訂課")]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }