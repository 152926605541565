const privateBookingLang = {
  messages: {
    tw: {
      choice_consultant: '指定顧問',
      select_consultant_here: '選擇顧問...',
      material_type: '教材類型',
      please_select: '請選擇',
      interested_topic: '適性分析',
      interested_materail: '適性教材',
      upload: '自行上傳',
      upload_file: '上傳檔案',
      upload_material: '上傳教材...',
      memo: '備註',
      no_consultant_alert: '若指定顧問選單沒有顧問可選擇，表示該時段顧問已被預定，但該時段仍可訂課，教務部門將會另外為您安排「此主題專業且附經驗之顧問」進行課程。',
      designatedMaterial: '指定自備教材',
      uploadYourFile: '上傳你的檔案',
      designatedMaterialNotice: '<u>注意：自備教材為客製化課程，將消耗堂數 <span class="text-danger font-weight-bold">2 堂</span></u><br><br>僅接受格式為 "ppt,pptx" 且大小 5MB 以下的檔案，請務必上傳英文内容之教材，並避免敏感及爭議性話題。',
      file: '檔案...',
      dislike_consultant_alert: '《注意! 您排除此顧問授課，若確定指定此顧問，WUWOW Jr將依您指定安排。》',
      appointConsultantRemark: '《註：若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。》',
      bookingRemark: '《註：若選擇未開放時段之顧問，須待顧問回覆同意後才算訂課成功。》',
    },
    en: {
      choice_consultant: 'Choice Consultant',
      select_consultant_here: 'Select Consultant Here...',
      material_type: 'Material Type',
      please_select: 'Please Select ',
      interested_topic: 'Interested Topic',
      interested_materail: 'Interested Materail',
      designatedMaterial: 'Designated Material',
      uploadYourFile: 'Upload Your File',
      upload: 'Upload',
      upload_file: 'Upload File',
      upload_material: 'Upload Material...',
      memo: 'Memo',
      no_consultant_alert: '',
      material_type_alert: '',
      file: 'file',
      dislike_consultant_alert: '',
      appointConsultantRemark: '',
      bookingRemark: '',
    },
  },
};

export {
  privateBookingLang
};
