<template lang="pug">
span {{ currentTime }}
</template>

<script type="text/javascript">
import { mapState } from 'vuex';
import moment from 'moment';
import LioshutanApi from '@/api';

export default {
  name: 'ServerTime',

  components: {},

  data() {
    return {
      currentTimeOut: '',
      cycleServeTimeOut: '',
    };
  },

  computed: {
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
  },

  created() {
    this.setServerTime();
    this.clockTimeOut();
  },

  mounted() {
    this.reSetServerTime();
  },

  beforeDestroy() {
    clearTimeout(this.currentTimeOut);
    clearTimeout(this.cycleServeTimeOut);
  },

  methods: {
    async setCurrentTime(time) {
      this.$store.dispatch('common/setCurrentTime', time);
    },

    async setServerTime(){
      const res = await LioshutanApi.common.getServerTime();
      const serverTime = moment(res.data).format('YYYY-MM-DD HH:mm:ss');
      this.setCurrentTime(serverTime);
    },

    clockTimeOut(){
      this.currentTimeOut = setTimeout(() => {
        const nextTime = moment(this.currentTime).add(1, 's').format('YYYY-MM-DD HH:mm:ss');
        this.setCurrentTime(nextTime);
        this.clockTimeOut();
      }, 1000);
    },

    reSetServerTime(){
      this.cycleServeTimeOut = setTimeout(() => {
        clearTimeout(this.currentTimeOut);
        this.setServerTime();
        this.currentTimeOut = this.clockTimeOut();
        this.reSetServerTime();
      }, 180000);
    },
  },
};
</script>
