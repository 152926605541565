var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    class: [_vm.booking_type === _vm.bookClassConstants.PRIVATE_BOOKING_TYPE.UPDATE ? 'row' : '']
  }, [_c('b-form-group', {
    staticClass: "font-size-cus",
    class: [_vm.booking_type === _vm.bookClassConstants.PRIVATE_BOOKING_TYPE.UPDATE ? 'col-sm-6' : ''],
    attrs: {
      "horizontal": _vm.booking_type !== _vm.bookClassConstants.PRIVATE_BOOKING_TYPE.UPDATE,
      "label-cols": 3,
      "breakpoint": "sm",
      "label": _vm.$t('choice_consultant'),
      "label-for": "ddlConsultant"
    }
  }, [_c('multiselect', {
    attrs: {
      "id": "ddlConsultant",
      "track-by": "teacher_id",
      "placeholder": _vm.$t('select_consultant_here'),
      "options": _vm.consultantList,
      "label": "english_name",
      "multiple": false,
      "clear-on-select": true,
      "close-on-select": true,
      "hide-selected": false,
      "preserve-search": true,
      "max-height": 300,
      "disabled": _vm.booking_type == 'private'
    },
    model: {
      value: _vm.custom_application.consultant,
      callback: function ($$v) {
        _vm.$set(_vm.custom_application, "consultant", $$v);
      },
      expression: "custom_application.consultant"
    }
  }), _vm.custom_application.consultant && _vm.custom_application.consultant.vip_like_consultant === 0 ? _c('p', {
    staticClass: "annotation"
  }, [_vm._v(_vm._s(_vm.$t('dislike_consultant_alert')))]) : _vm._e()], 1)], 1), _vm.newRule ? _c('b-row', {
    staticClass: "mt-3"
  }, [_c('b-col', {
    staticClass: "modal-label",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v(_vm._s(_vm.$t('designatedMaterial')))]), _c('b-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('b-row', {
    staticClass: "text-left"
  }, [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "upload-file-check-box",
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.custom_application.isUploadMaterial,
      callback: function ($$v) {
        _vm.$set(_vm.custom_application, "isUploadMaterial", $$v);
      },
      expression: "custom_application.isUploadMaterial"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm.custom_application.isUploadMaterial ? _c('b-form-file', {
    attrs: {
      "state": Boolean(_vm.custom_application.materialFile),
      "placeholder": _vm.$t('uploadYourFile'),
      "accept": ".ppt, .pptx"
    },
    model: {
      value: _vm.custom_application.materialFile,
      callback: function ($$v) {
        _vm.$set(_vm.custom_application, "materialFile", $$v);
      },
      expression: "custom_application.materialFile"
    }
  }) : _vm._e(), _vm.custom_application.materialFile ? _c('p', {
    staticClass: "mt-1 text-success"
  }, [_vm._v("已選擇檔案： " + _vm._s(_vm.custom_application.materialFile.name))]) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.custom_application.isUploadMaterial ? _c('b-alert', {
    staticClass: "text-left mt-2",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.$t('designatedMaterialNotice'))
    }
  })]) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }