<template lang="pug">
  .currentClassApplication
    //- 點選取消預約的modal
    b-modal(
      ref="cancelClassAlertModal"
      title="是否取消訂課？"
      @ok="cancelClassAlertOk"
      header-bg-variant="primary"
      centered
      ok-title="確定"
      cancel-title="取消"
    )
      .text-center.h4(v-if="cancelTarget")
        span(class="classTitle") {{ `< ${$t(`classType.class_type_${cancelTarget.classApplication.classTypeId}`)} >` }}
        br
        br
        span 課程時間：
        span(class="classtime") {{ cancelTarget.classApplication.classTime }}

    //-訂課紀錄卡片
    div
      .wuwow-card
        .wuwow-card-head
          h4.wuwow-card-title(v-if="showFilter") 訂課紀錄
          h4.wuwow-card-title(v-else) 目前訂課
        .wuwow-card-body
          //- filter(電腦版)
          .container.d-none.d-md-flex.flex-column(v-if="showFilter")
            .row.mb-4.justify-content-stretch.align-items-center
              .col-md-2.my-2
                label 時間範圍
                select-date-and-time(:datetime.sync="filter.datetimeRange" placeholder="開始時間 ~ 結束時間" :minuteStep="30")
              .col-md-2.my-2
                label 授課顧問
                b-form-input.border-10(v-model="filter.teacher" placeholder="輸入顧問名字")
              .col-md-2.my-2
                label 堂數使用
                select.border-10(v-model="filter.session").form-control
                  option(
                    v-for="(item, index) in SessionCount" :key="`session-count-${index}`"
                    :value="item"
                  )
                    | {{ $t(`sessionCount.${item}`) }}
              .col-md-2.my-2
                label 課程類型
                select.border-10(v-model="filter.bookingClassType").form-control
                  option(
                    v-for="(item, index) in BookingClassType" :key="`booking-type-${index}`"
                    :value="item"
                  )
                    | {{ $t(`bookingClassType.${item}`) }}
              .col-md-2.my-2
                label 教材類型
                select.border-10(v-model="filter.bookingClassMaterialType").form-control
                  option(
                    v-for="(item, index) in BookingClassMaterialType" :key="`booking-class-material-type-${index}`"
                    :value="item"
                  )
                    | {{ $t(`bookingClassMaterialType.${item}`) }}
              .col-md-2.my-2
                label 狀態
                select.border-10(v-model="filter.bookingClassStatus").form-control
                  option(
                    v-for="(item, index) in BookingClassStatus" :key="`booking-class-status-${index}`"
                    :value="item"
                  )
                    | {{ $t(`bookingClassStatus.${item}`) }}
            .row.justify-content-end
              .col-md-3
                button(@click="getClassNextApplication(1)").btn.btn-block.btn-primary 搜尋
          //- 刪課提示
          b-alert(:show="alert.show" dismissible="" :variant="alert.successAlert? 'success' : 'danger'") {{ alert.message }}
          //- table(電腦版)
          table.d-none.d-md-table.table.table-hover.table-clsapc-record.text-center
            thead.thead-default
              tr
                th 上課時間
                th 課程型態
                th 顧問
                th 堂數使用
                th 操作 / 狀態
            tbody(v-if="classNextApplication.length !== 0")
              tr(v-for="(item,index) in classNextApplication" :key="item.classApplication.id")
                td {{ item.classApplication.classTime }}
                td
                  template(v-if="item.customized.isUploadMaterial")
                    button.btn.btn-link.p-0.jr-home-main-color(@click="openMaterialModal(item.classApplication.id)")
                      font-awesome-icon.mr-1(icon="download")
                      |  {{ $t(`classType.class_type_${item.classApplication.classTypeId}`) }}
                  template(v-else) {{ $t(`classType.class_type_${item.classApplication.classTypeId}`) }}
                td
                  span(v-if="item.classroom.teacher.englishName") {{ item.classroom.teacher.englishName }}
                  span(v-else) -
                td {{ $t(`sessionCount.${item.classApplication.useSessionCode}`) }}
                td
                  //- template(v-if="item.classApplication.deleted")
                  //-   span.text-dark 訂課取消
                  //- template(v-else-if="canNotCanlcelBook(item.classApplication.classTypeId, item.classApplication.cancelStatus)")
                  //-   span.text-dark 無法取消
                  //- template(v-else-if="canCancel(item)")
                  //-   button.p-0.btn.btn-link.text-danger(@click="clickCancelClassButton(item)")
                  //-     | 取消預約
                  //- template(v-else)
                  //-   span.text-dark {{ $t(`bookingClassStatus.${item.classApplication.showCodeName}`) }}

                  //- canCancel => (f)判斷時間
                  //- cancelStatus => (value)後端給的可否刪除的狀態
                  //- canNotCanlcelBook => (f)課程類別、取消狀態 (2022-08-26 課程類別的判斷搬到後端)
                  template(v-if="canCancel(item)")
                    button.p-0.btn.btn-link.text-danger(@click="clickCancelClassButton(item)")
                      | 取消預約
                  template(v-else)
                    span.text-dark {{ $t(`bookingClassStatus.${item.classApplication.showCodeName}`) }}
          //- table手機板(卡片形式)
          .mobile-card.d-block.d-md-none.m-4
            a.filter-icon.d-flex.justify-content-end.align-items-center(v-b-modal.mobile-fiter v-if="showFilter")
              font-awesome-icon.mr-1(icon="filter")
              | 篩選
            .border-10.card-shadow.my-3.p-4.w-100(v-for="(item,index) in classNextApplication" :key="item.classApplication.id")
              p.card-date.jr-text-light-color.mb-0.pb-1 {{ item.classApplication.classTime }}
              .d-flex.justify-content-between.my-2
                .jr-text-light-color 課程型態
                div
                  template(v-if="item.customized.isUploadMaterial")
                    button.btn.btn-link.p-0(@click="openMaterialModal(item.classApplication.id)")
                      font-awesome-icon.mr-1(icon="download")
                      |  {{ $t(`classType.class_type_${item.classApplication.classTypeId}`) }}
                  template(v-else) {{ $t(`classType.class_type_${item.classApplication.classTypeId}`) }}
              .d-flex.justify-content-between.my-2
                .jr-text-light-color 顧問
                div
                  span(v-if="item.classroom.teacher.englishName") {{ item.classroom.teacher.englishName }}
                  span(v-else) -
              .d-flex.justify-content-between.my-2
                .jr-text-light-color 堂數使用
                div {{ $t(`sessionCount.${item.classApplication.useSessionCode}`) }}
              .d-flex.justify-content-between.my-2
                .jr-text-light-color 狀態
                div
                  template(v-if="canCancel(item)")
                    button.btn.btn-link.text-danger.p-0(@click="clickCancelClassButton(item)")
                      | 取消預約
                  template(v-else)
                    span {{ $t(`bookingClassStatus.${item.classApplication.showCodeName}`) }}
        .wuwow-card-footer
          b-pagination(
            align="center"
            :total-rows="pagination.total"
            v-model="next_currentPage"
            :per-page="Number(pagination.perPage)"
            @input="nextCurrentPageChange()"
          )
    private-book-class-modal(:privateBookInfo="privateBookInfo")
    //- 手機板篩選器
    b-modal#mobile-fiter.d-block.d-md-none.mobile-fiter(centered ok-only ok-title='確認送出' @ok="getClassNextApplication(1)")
      h4.mobile-fiter-main-color.text-center 篩選器
      .row.mb-4.justify-content-stretch.align-items-center.p-2
        .col-12.my-2
          .row
            label.col-4 開始/結束日期
            select-date-and-time.p-0.col-8(:datetime.sync="filter.datetimeRange" placeholder="開始時間 ~ 結束時間" :minuteStep="30")
        .col-12.my-2
          .row
            label.col-4 課程類型
            select.col-8.border-10(v-if="BookingClassType" v-model="filter.bookingClassType").form-control
              option(
                v-for="(item, index) in BookingClassType" :key="`mobil-booking-type-${index}`"
                :value="item"
              )
                | {{ $t(`bookingClassType.${item}`) }}
        .col-12.my-2
          .row
            label.col-4 教材類型
            select.col-8.border-10(v-model="filter.bookingClassMaterialType").form-control
              option(
                v-for="(item, index) in BookingClassMaterialType" :key="`mobil-booking-class-material-type-${index}`"
                :value="item"
              )
                | {{ $t(`bookingClassMaterialType.${item}`) }}
        .col-12.my-2
          .row
            label.col-4 授課顧問
            b-form-input.col-8.border-10(v-model="filter.teacher" placeholder="輸入顧問名字")
        .col-12.my-2
          .row
            label.col-4 堂數使用
            select.col-8.border-10(v-model="filter.session").form-control
              option(
                v-for="(item, index) in SessionCount" :key="`mobil-session-count-${index}`"
                :value="item"
              )
                | {{ $t(`sessionCount.${item}`) }}
        .col-12.my-2
          .row
            label.col-4 狀態
            select.col-8.border-10(v-model="filter.bookingClassStatus").form-control
              option(
                v-for="(item, index) in BookingClassStatus" :key="`mobil-booking-class-status-${index}`"
                :value="item"
              )
                | {{ $t(`bookingClassStatus.${item}`) }}
</template>

<script type="text/javascript">
import { MODAL } from '@/store';
import lioshutanApi from '@/api';
import {
  IS_OPEN,
  SET_IS_OPEN,
  SET_PAYLOAD,
} from '@/store/modules/modal';
import api, {
  Platform,

  BookingClassStatus,
  BookingClassMaterialType,
  BookingClassType,
  SessionCount,
} from '@lioshutan/api-package';
import LioshutanApi from '@/api';
import bookClassConstants from '@/constants/bookClass.js';
import privateBookClassModal, {
  uuid as privateBookClassModalUuid,
} from '@/components/vip/currentClassApplication/privateBookClassModal/index.vue';
import { mapState, mapMutations } from 'vuex';
import moment from 'moment';
import i18n from './lang';
import selectDateAndTime from '@/components/filter/selectDateAndTime';

export default {
  name: 'CurrentClassApplication',

  components: {
    privateBookClassModal,
    selectDateAndTime,
  },

  props: {
    init: {
      type: Object,
      default: null,
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    // 為區分是哪頁引用這頁帶入的參數
    // 預約訂課頁面:'bookClass' / 訂課紀錄頁面:'bookClassRecord'
    showPage: {
      type: String,
      default: function() {
        return '';
      },
    },
  },

  i18n,

  data() {
    return {
      BookingClassStatus,
      BookingClassMaterialType,
      BookingClassType,
      SessionCount,
      cancelTarget: null,
      filter: {
        datetimeRange: [
          // 2022-07-08 卡片#773 預設時間-6個月~+1個月
          moment(new Date()).subtract(6, 'month').format('YYYY-MM-DD HH:mm'),
          moment(new Date()).add(31, 'day').format('YYYY-MM-DD 23:30'),
        ],
        teacher: null,
        session: 'all',
        bookingClassType: 'all',
        bookingClassMaterialType: 'all',
        bookingClassStatus: 'all',
      },
      next_currentPage: 1,
      past_currentPage: 1,
      alert: {
        show: false,
        message: '',
        successAlert: true,
      },
      dismissCountDown: 0,
      cancelClassIndex: 0,
      classNextApplication: [],
      pagination: {},
      privateBookInfo: null,
      customizedClassroomRule: null,
    };
  },

  computed: {
    ...mapState(MODAL, [
      IS_OPEN,
    ]),
    youtubeModalIsOpen() {
      return this[IS_OPEN][privateBookClassModalUuid];
    },
    ...mapState({
      token: (state) => state.auth.token,
      currentTime: (state) => state.common.currentTime,
      bookClassDataUpdate: (state) => state.bookClass.bookClassDataUpdate,
    }),
  },

  watch: {
    bookClassDataUpdate(value){
      if (value) {
        this.getClassNextApplication();
      }
    },
    init() {
      this.filter = {
        datetimeRange: [`${this.init.start} 00:00`, `${this.init.end} 23:30`],
        teacher: null,
        bookingClassType: 'all',
        bookingClassMaterialType: 'all',
        bookingClassStatus: 'booking_success',
        session: 'all',
      };

      this.getClassNextApplication(1);
    },
  },

  async created() {
    if (this.showPage !== bookClassConstants.SHOW_PAGE.BOOK_CLASS) {
      this.getClassNextApplication(1);
    }
    await this.getStudentPorfile();
  },

  methods: {
    ...mapMutations(MODAL, [
      SET_IS_OPEN,
      SET_PAYLOAD,
    ]),
    async openMaterialModal(id) {
      const response = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .educational(this.token)
        .getBookingDetail({
          id: id,
          platform: Platform.WUWOW_JUNIOR,
        });

      this.privateBookInfo = response.data.classApplication;

      this.setIsOpen({
        target: privateBookClassModalUuid,
        status: !this.youtubeModalIsOpen,
      });
    },
    // 是否顯示取消按鈕
    canCancel(item) {
      const { classTime, cancelStatus } = item.classApplication;
      const currentTime = this.currentTime;
      // const currentTime = '2023-03-30 13:00:00';
      const diffTime = moment(currentTime).diff(classTime, 'seconds');
      // console.log(this.customizedClassroomRule.new_rule);
      // 只要是新身分就是24小時前才可以取消
      if (cancelStatus) {
        return cancelStatus;
      }
      if (moment(classTime).isAfter()) {
        if (this.customizedClassroomRule.new_rule) {
          //  大於 24小時可取消
          return Math.abs(diffTime) > 86400 && cancelStatus;
        }
        // 課堂開始15分鐘內且，該課堂尚未被取消的情況下可被刪除
        return Math.abs(diffTime) > 900 && cancelStatus;
      }

      return false;
    },
    getStudentPorfile(){
      lioshutanApi.vip.getBaseProfile().then((result) => {
        this.customizedClassroomRule = result.data.customized_classroom_rule;
        // 測試切換 新舊學員
        // this.customizedClassroomRule.new_rule = false;
      });
    },
    clickCancelClassButton(item) {
      this.cancelTarget = item;
      this.$refs['cancelClassAlertModal'].show();
      // this.cancelClassIndex = index;
    },
    openPrivateBookModal(item) {
      this.$bvModal.show('privateBookModal');
      this.privateBookInfo = item;
    },
    async cancelClassAlertOk() {
      try {
        const response = await LioshutanApi.bookingClass.cancelBookClass(this.cancelTarget.classApplication.id);
        this.alert.message = response.data.message;
        this.alert.show = true;
        this.alert.successAlert = true;
        this.$store.dispatch('bookClass/setBookClassDataUpdate', true);
        // 如果是目前訂課則觸發更新calendar
      } catch (error) {
        this.alert.show = true;
        this.alert.successAlert = false;
        this.alert.message = error.response.data.message;
      }
    },
    nextCurrentPageChange() {
      this.getClassNextApplication(this.next_currentPage);
    },
    async getClassNextApplication(page) {
      // 顯示為"目前訂課"的 要正序 其他是倒序
      const sorts = this.showPage === bookClassConstants.SHOW_PAGE.BOOK_CLASS ? ['class_time|asc'] : ['class_time|desc'];
      this.next_currentPage = page;
      const params = {
        searchType: bookClassConstants.GET_CLASS_RECORD_PARAMS_KEY.STATUS.CLASS_RECORD,
      };
      if (this.showPage === bookClassConstants.SHOW_PAGE.BOOK_CLASS) {
        params.deleted = bookClassConstants.GET_CLASS_RECORD_PARAMS_KEY.SHOW_DELETE.YES;
      }
      if (page) {
        params.page = page;
      }

      const response = await api
        .setDNS(process.env.VUE_APP_BASE_API)
        .educational(this.token)
        .getBookingClassData({
          startTime: moment(this.filter.datetimeRange[0]).format('YYYY-MM-DD HH:mm:ss'),
          endTime: moment(this.filter.datetimeRange[1]).format('YYYY-MM-DD HH:mm:ss'),
          bookingClassTypes: [this.filter.bookingClassType],
          materialTypes: [this.filter.bookingClassMaterialType],
          bookingClassStatus: [this.filter.bookingClassStatus],
          platform: Platform.WUWOW_JUNIOR,
          page,
          perPage: 15,
          teacherName: this.filter.teacher,
          session: [this.filter.session],
          sorts: sorts,
        });

      this.classNextApplication = response.data.classApplication;
      this.pagination = response.meta.pagination;
      this.$store.dispatch('bookClass/setBookClassDataUpdate', false);
    },
    canNotCanlcelBook(classType, cancelStatus){
      // 13:上傳教材
      const canNotCanlcelType = [3, 4, 8, 16];
      return canNotCanlcelType.includes(classType) || !cancelStatus;
    },
  },
};
</script>
<style lang="scss" scoped>
.w-65 {
  width: 65%;
}
.modal-text {
  font-size: 20px;
  padding: 15px 0;
}
.border-10 {
  border-radius: 10px;
}
.card-shadow {
  box-shadow: 0px 0px 6px #89898929;
}
// table手機板-卡片形式
.mobile-card {
  font-size: 14px;
  .filter-icon {
    color: #245FFFEA;
  }
  .card-date {
    border-bottom: 1px solid var( --main-color );
  }
}
.mobile-fiter-main-color {
  color: var( --home-main-color );
}
/deep/ .modal-content {
  h4 {
    margin-top: -48px;
  }
  .btn {
    margin: 0 auto;
    width: 40%;
    border: 5px;
  }
  .close {
    background-color: #D05959;
    margin: 2px;
    border-radius: 8px;
    padding: 6px 10px;
    color: white;
    opacity: 1;
    z-index: 1;
  }
  .modal-header {
    align-items: center;
    h5 {
      font-size: 20px;
    }
  }
  .modal-body {
    padding: 30px 0 10px 0;
  }
  .modal-footer {
    padding: 20px 10px;
  }

  @media screen and ( max-width: 414px ){
    .modal-body {
      .h4 {
        font-size: 18px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
// 為了限制套件的input有圓角
::v-deep .mx-input {
  border-radius: 10px;
}
// ::v-deep .modal-header {
//   background-color: white !important;
// }
</style>
