export default {
  messages: {
    tw: {
      classType: {
        class_type_1: '1 on 1 家教班',
        class_type_2: '1 ~ 3人微班級',
        class_type_3: 'Demo',
        class_type_5: 'Demo',
        class_type_6: '百家書苑',
        class_type_11: '1 on 1 家教班',
        class_type_12: '特殊時段詢問',
        class_type_13: '1 on 1 自備教材 家教班',
      },

      bookingClassStatus: {
        all: '請選擇狀態',
        ask_proposal: '申請中',
        ask_rejects: '申請未成功',
        can_not_cancel: '超過取消時間',
        booking_cancel: '訂課取消',
        booking_success: '訂課成立',
        show: '已出席',
        no_show: '未出席',
        refund: '開課異常（退還點數）',
        not_start: '課程尚未開始',
      },

      bookingClassMaterialType: {
        all: '請選擇教材類型',
        customize_material: '自備教材',
        level_material: '系統教材',
      },

      bookingClassType: {
        all: '請選擇課程類型',
        wuwow_junior_normal: '一般課程',
        wuwow_junior_self_material: '客製化課程',
        wuwow_junior_special: '特殊時段申請',
      },

      sessionCount: {
        all: '請選擇堂數',
        one_class: '1 堂',
        two_classes: '2 堂',
      },
    },
    en: {
      classType: {
        class_type_1: '1 on 1',
        class_type_2: '1 vs 3',
        class_type_3: 'Demo',
        class_type_5: 'Demo',
        class_type_6: 'Academy Talks',
        class_type_11: '1 on 1',
        class_type_12: '特殊時段詢問',
        class_type_13: '1 on 1 自備教材 家教班',
      },
      bookingClassStatus: {
        ask_proposal: '申請中',
        ask_rejects: '申請未成功',
        can_not_cancel: '超過取消時間',
        booking_cancel: '訂課取消',
        booking_success: '訂課成立',
        show: '已出席',
        no_show: '未出席',
        refund: '開課異常（退還點數）',
        not_start: '課程尚未開始',
      },

      bookingClassMaterialType: {
        customize_material: '客製教材',
        level_material: '等級教材',
      },

      bookingClassType: {
        wuwow_junior_normal: '一般課程',
        wuwow_junior_self_material: '客製化課程',
        wuwow_junior_special: '特殊時段申請',
      },

      sessionCount: {
        one_class: '1 Session',
        two_classes: '2 Session',
      },
    },
  },
};
