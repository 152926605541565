<template lang="pug">
  .book-class
    server-time-clock
    b-row(align-h='between')
      b-col(md='9')
        .wuwow-card.phone-width.schedule-card
          .wuwow-card-head.text-center
            h4.wuwow-card-title {{$t('bookClassTable')}}
          .wuwow-card-body
            .set-bind-notify-section
              a.set-bind-notify(href='/dojo/account/profile#notification_push')
                font-awesome-icon(:icon="['far', 'bell']")
                |  {{$t('setBookClassAlert')}}
            .mobile-message.text-center
              br
              b-alert(variant='primary' show='') {{$t('cilckDateToBookClass')}}
            .calendar-wrapper
              vue-full-calendar(ref="vue-calendar" :options="calendarOptions")
            .are-progress-info
              span.txt-finish-num {{$t('countBookClass')}} : {{`${weekClassCount}${$t('class')}`}}
              span.txt-lag-num {{$t('countBookClassBbhind')}} : {{`${weekSuggestClass}${$t('class')}`}}
      b-col(md='3')
        .wuwow-right-side
          .wuwow-right-side-head
            h4.wuwow-right-side-title &#x8A02;&#x8AB2;&#x9808;&#x77E5;
          .wuwow-right-side-body
            picture
              source(srcset='https://cdn.wuwow.tw/vip/class_time_mobile.jpg' media='(max-width: 768px)')
              img.img-fluid(srcset='https://cdn2.wuwow.tw/wuwow-junior/vip/class-time-web.svg' alt='Class Schedule')
            br
            br
            b-alert(show='' variant='secondary' v-for='(data,index) in note' :key='index')
              span.title {{data.title}}
              br
              span.content(v-html="data.content")
    br
    application-modal(:bookingDate="bookingDate" :key="reloadBooking" @bookingClassChange='bookingClassChange')
    //- 切換月份切換 modal
    b-modal#monthModal(
    v-model="monthModal"
    @ok="switchMonth()"
    :title="'訊息'"
    :ok-title="'確定'"
    :cancel-title="'取消'"
    centered
    header-bg-variant="primary")
      h4.text-center
        | 是否跳至
        span.text-danger {{ formatMonth(toMonth) }}
        | 月進行訂課
</template>

<script type="text/javascript">
import api, { Platform } from '@lioshutan/api-package';
import { bookClassLang } from '@/components/vip/bookClass/lang';
// import { FullCalendar } from 'vue-full-calendar';
import vueFullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import applicationModal from '@/components/vip/bookClass/applicationModal/index.vue';
import serverTimeClock from '@/components/serverTimeClock';
import 'fullcalendar/dist/fullcalendar.css';
import moment from 'moment';
import { alertHtmlMessage } from '@/utils/sweetAlert.js';
import lioshutanApi from '@/api';
import bookClassConstants from '@/constants/bookClass.js';
import lodash from 'lodash';
import { mapState } from 'vuex';

export default {
  name: 'BookClass',

  components: {
    // FullCalendar,
    serverTimeClock,
    applicationModal,
    vueFullCalendar,
  },

  i18n: bookClassLang,

  data() {
    return {
      events: [],
      note: [{
        title: '如何開始訂課？',
        content: '點擊想要的日期，即可預訂當日課程。',
      }, {
        title: '可訂課日期？',
        content: '訂課當日後 30 天皆可預訂。',
      }, {
        title: '消耗堂數如何計算？',
        content: '每堂訂課須使用堂數 1 堂，如有下列特殊需求則須使用堂數 2 堂。<hr><strong>一、特殊時段申請：</strong><br>若想預訂特定顧問，但該顧問開放時段已額滿或您無法在該時段進行課程，你可以向顧問提出未開放時段課程申請，此類訂課須待顧問回復同意後才算成功訂課！<br><br><strong>二、客製教材訂課：</strong><br>若提早三天預訂課程（72小時），你可以在訂課介面中，選擇自行上傳想要學習的教材，讓顧問為您客製化設計一堂課程！',
      }],

      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        // timeZone: 'local',

        initialView: 'dayGridMonth',
        height: 'auto',
        headerToolbar: {
          left: 'customPrev,customNext',
          center: 'title',
          right: '',
        },
        buttonText: {
          today: 'Today',
        },
        // 自訂下一頁按鈕
        customButtons: {
          customPrev: {
            icon: 'chevron-left',
            click: this.customPrev,
          },
          customNext: {
            icon: 'chevron-right',
            click: this.customNext,
          },
        },
        dateClick: this.handleEventClick,
        events: this.handleEvents,
        eventClick: this.handleEventClick,
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        },
      },

      clickDay: '',

      bookingDate: {
        bookingTitle: '',
        bookingDateValue: '',
        classApllication: [],
      },

      reloadBooking: 0,

      can_book_1on1_application: false,

      scheduleDate: {
        start: '',
        end: '',
      },

      classApplicationByDay: {},
      currentApplication: [],
      pagination: {},

      // 停課設定（點擊當天行事曆會跳出警告）設定此處開始結束日及警告文字
      disableDate: {
        start: '2024-02-06 23:59:59',
        end: '2024-02-15 00:00:00',
        alertText: '<br><h3>此時段無法訂課</h3>🔸2024-02-07(三)09:00~2024-02-15(五)00:00<br>並於2024-02-15 09:00恢復正常上課',
      },

      weekClassCount: 0,
      weekSuggestClass: 0,
      calendarApi: null,

      // 切換月份切換 modal
      monthModal: false,
      toMonth: moment().format(),
    };
  },

  mounted(){
    this.calendarApi = this.$refs['vue-calendar']['getApi']();
  },

  computed: {
    ...mapState('auth', ['token']),
    ...mapState({
      currentTime: state => state.common.currentTime,
      bookClassDataUpdate: (state) => state.bookClass.bookClassDataUpdate,
      bookcanlendarDataUpdate: (state) => state.bookClass.bookcanlendarDataUpdate,
    }),
  },

  watch: {
    bookClassDataUpdate(value){
      if (value) {
        this.bookingClassChange();
      }
    },
  },

  async created() {
    const response = await api
      .setDNS(process.env.VUE_APP_BASE_API)
      .analysis(this.token)
      .getStudentPointSuggestion({
        platform: Platform.WUWOW_JUNIOR,
      });
    this.weekClassCount = response.data.suggestion.weekClassCount;
    this.weekSuggestClass = response.data.suggestion.weekSuggestClass;
  },

  methods: {
    async handleEvents({ start, end }, success, fail){
      this.timeChange(start, end);
      this.scheduleDate.start = `${moment(start).format('YYYY-MM-DD')} 00:00:00`;
      this.scheduleDate.end = `${moment(end).format('YYYY-MM-DD')} 23:59:59`;
      try {
        const response = await this.queryClassApplication();
        success(response);
      } catch (error) {
        fail(error);
      }
    },
    handleEventClick(value){
      if (value.allDay) { // 點擊整日
        this.onDayClick(value.date);
      } else { // 點擊單一時間
        this.onDayClick(value.event.start);
      }
    },
    timeChange(start, end) {
      this.$emit('timeChange', {
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      });
    },
    async queryClassApplication() {
      const params = {
        classTimeStart: this.scheduleDate.start,
        classTimeEnd: this.scheduleDate.end,
        searchType: bookClassConstants.GET_CLASS_RECORD_PARAMS_KEY.STATUS.CLASS_RECORD,
        deleted: bookClassConstants.GET_CLASS_RECORD_PARAMS_KEY.SHOW_DELETE.YES,
        needPaginator: bookClassConstants.GET_CLASS_RECORD_PARAMS_KEY.NEED_PAGINATOR.NO,
      };
      const response = await lioshutanApi.bookingClass.getClassPastApplication(params);
      this.pagination = response.data.meta.pagination;
      // 先把被刪除的課拿掉
      const classApplication = lodash.filter(response.data.data, (item) => {
        return item.class_deleted === 0;
      });
      this.setClassApplicationByDay(classApplication);
      this.setCurrentApplication(classApplication);
      this.$store.dispatch('bookClass/setBookClassDataUpdate', false);
      return this.setScheduleEvent(classApplication);
    },
    // 設定顯示在目前訂課的資料(未來時間的課程才顯示)
    setCurrentApplication(data){
      this.currentApplication = lodash.filter(data, (item) => {
        return moment(item.class_time).isAfter(this.currentTime);
      });
    },
    // 將ＡＰＩ回來的訂課資料以天為單位整理
    setClassApplicationByDay(data){
      this.classApplicationByDay = lodash.groupBy(data, (item) => {
        return item.class_time.substr(0, 10);
      });
      this.bookingDate.classApllication = this.classApplicationByDay[this.clickDay];
    },
    // 將ＡＰＩ回來的訂課資料塞進Schedule的Event裡
    setScheduleEvent(data){
      // this.clearEvent(); // 清空events
      const events = [];
      for (let i = 0; i < data.length; i++) {
        const start = moment(data[i].class_time),
              end = moment(start).add(1, 'seconds'),
              timeColor = this.getClassColor(start);
        events.push({
          start: start.format('YYYY-MM-DD HH:mm:ss'),
          end: end.format('YYYY-MM-DD HH:mm:ss'),
          className: ['event-box', timeColor],
        });
      }
      return events;
    },

    formatMonth(timeString) {
      return moment(timeString).format('M');
    },
    // 自訂上一月按鈕
    customPrev() {
      // 電腦當月及下一月
      const currentMonth = moment().add(0, 'months').format('M');
      const nextMonth = moment().add(1, 'months').format('M');

      const prevTo = moment(this.calendarApi.view.currentStart).add(-1, 'months').format();
      this.toMonth = prevTo;

      if (moment(prevTo).format('M') === currentMonth || moment(prevTo).format('M') === nextMonth) {
        this.monthModal = true;
        return;
      }
      this.switchMonth();
    },
    // 自訂下一月按鈕
    customNext() {
      // 電腦當月及下一月
      const currentMonth = moment().add(0, 'months').format('M');
      const nextMonth = moment().add(1, 'months').format('M');

      const nextTo = moment(this.calendarApi.view.currentStart).add(1, 'months').format();
      this.toMonth = nextTo;

      if (moment(nextTo).format('M') === currentMonth || moment(nextTo).format('M') === nextMonth) {
        this.monthModal = true;
        return;
      }
      this.switchMonth();
    },

    // full calendar 切換至月份
    switchMonth() {
      this.calendarApi.gotoDate(this.toMonth);
    },

    onDayClick(time, jsEvent, view) {
      this.clickDay = '';
      this.bookingDate.bookingTitle = moment(time).format('MM/DD');
      this.clickDay = moment(time).format('YYYY-MM-DD');
      // if (event.start) {
      //   this.bookingDate.bookingTitle = event.start.format('MM/DD');
      //   clickDay = event.start.format('YYYY-MM-DD');
      // } else {
      //   this.bookingDate.bookingTitle = event.format('MM/DD');
      //   clickDay = event.format('YYYY-MM-DD');
      // }

      // TODO: 點擊行事曆可以出現modal的日子及出現停課訊息的日子需要再調整
      // 點擊下個月也需要調整 是否需要往下個月的modal要詢問
      if (this.disableBookDate(moment(this.clickDay))) {
        // 停課
        alertHtmlMessage('警告', 'error', this.disableDate.alertText, '了解!');
      } else {
        this.bookingDate.bookingDateValue = this.clickDay;
        this.bookingDate.classApllication = this.classApplicationByDay[this.clickDay];
        // 今天以前點擊不會跳出訂課視窗
        const isBeforeToday = moment(this.clickDay).isBefore(moment(this.now).format('YYYY-MM-DD'));
        if (!isBeforeToday) {
          // 日曆日期與點擊日期不同月跳出切換月份 modal
          const calendarMonth = moment(this.calendarApi.view.currentStart).format('M');
          const clickTo = moment(time).format();

          if (moment(clickTo).format('M') !== calendarMonth) {
            this.toMonth = clickTo;
            this.monthModal = true;
            return;
          }

          this.openClassApplicationModal();
        }
      }
    },
    // 不給訂課日期
    disableBookDate(date){
      return date.isBetween(this.disableDate.start, this.disableDate.end, 'second');
    },
    openClassApplicationModal() {
      this.$bvModal.show('classApplicationModal');
    },
    getClassColor(time){
      const morningClass = 'event-moring',
            afternoonClass = 'event-afternoon',
            eveningClass = 'event-evening',
            overtimeClass = 'overtime';
      const now = moment().format('YYYY-MM-DD HH:mm:ss'),
            eventTime = moment(time.format('YYYY-MM-DD HH:mm:ss')),
            afternoon = moment(time.format('YYYY-MM-DD 12:30:00')),
            evening = moment(time.format('YYYY-MM-DD 18:30:00'));

      // 設定事件在行事曆上面的顏色
      if (eventTime.isAfter(now)) {
        if (eventTime.isBefore(afternoon)) { // 早上（9:00-12:00）=> 黃色
          return morningClass;
        } else if (eventTime.isBefore(evening)) { // 下午（12:30-18:00）=> 紅色
          return afternoonClass;
        } else { // 晚上（18:30-24:00）=> 藍色
          return eveningClass;
        }
      } else {
        return overtimeClass;
      }
    },
    bookingClassChange(){
      this.calendarApi.refetchEvents();
    },
  },
};
</script>
<style scoped>
.calendar-wrapper{
  display: inline-flex;
}
/* 隱藏event的點 拿掉就知道我在說啥了*/
::v-deep .fc-daygrid-event-dot{
  display: none;
}
::v-deep .overtime {
  background: #959595;
  border-color: #959595;
}

::v-deep .fc-toolbar-title{
  margin-right: 100px;
}

::v-deep .fc .fc-button {
  padding-top: 3px;
}

::v-deep .event-box{
  cursor: pointer;
  padding-left: 2px;
  transition: all 0.1s ease-out;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

::v-deep .event-box:hover {
  text-shadow: none;
}

::v-deep .event-moring {
  background: #ffc200;
  border-color: #ffc200;
}

::v-deep .event-afternoon {
  background: #f05a37;
  border-color: #f05a37;
}

::v-deep .event-evening {
  background: #0d6fd8;
  border-color: #0d6fd8;
}

::v-deep .overtime {
  background: #959595;
  border-color: #959595;
}

::v-deep .overtime:hover {
  cursor: not-allowed;
}

::v-deep .fc-event{
  display: flex;
}
</style>
