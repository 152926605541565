<template lang="pug">
  div
    book-class(@timeChange="timeChange")
    current-class-application(
      :show-filter="false"
      :showPage="bookClassConstants.SHOW_PAGE.BOOK_CLASS"
      :init="timeRange"
    )
</template>

<script type="text/javascript">
import BookClass from '@/components/vip/bookClass/index.vue';
import CurrentClassApplication from '@/components/vip/currentClassApplication/index.vue';
import bookClassConstants from '@/constants/bookClass.js';

export default {
  name: 'BookClassView',

  components: {
    BookClass,
    CurrentClassApplication,
  },

  data() {
    return {
      timeRange: null,
      bookingClassApplication: {},
      bookClassConstants: bookClassConstants,
    };
  },

  computed: {},

  watch: {},

  mounted() {
  },

  methods: {
    timeChange(data) {
      this.timeRange = data;
    },
  },
};
</script>

<style lang="scss">
@import './bookClass';
@import '@/styles/transition';
</style>
