var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "txt-system-time"
  }, [_c('font-awesome-icon', {
    staticClass: "clock-icon fa-2x",
    attrs: {
      "icon": ['far', 'clock']
    }
  }), _vm._v("  "), _c('server-time')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }