<template lang="pug">
  div
    transition(name='page-fade')
      .float-modal(v-show='show_select_alert' style='height:auto;')
        .wuwow-card
          .wuwow-card-head
            h4.wuwow-card-title(align='center') 是否取消訂課 ?
        p.text-center(v-html='select_alert_message' style='font-size:18px;')
        hr
        div(style='width:100%; text-align:left; padding:0px 15px 0px 15px;' v-if='this.cancel_class.cancel_class_type === 1')
          h4.text-center
            i.far.fa-frown
            |  請選擇取消原因
            span.red-text(style='font-size:15px;') (必選)
          .cancel-book-reason-box
            b-form-radio-group(v-model='cancel_class.cancel_class_reason_value' :options='cancel_class_options' name='radios-stacked' stacked='')
          b-form-textarea#textarea(v-if="cancel_class.cancel_class_reason_value === '38' || cancel_class.cancel_class_reason_value === '39' || cancel_class.cancel_class_reason_value === '42'" v-model='cancel_class.cancel_class_reason_text' placeholder='請輸入原因...(請勿包含空白)' rows='3' max-rows='6')
          br
        b-container
          b-row.text-center
            b-col
              b-button(md='auto' @click='cancel_class_alert_cancel') 取消
            b-col
              b-button(md='auto' variant='primary' @click='cancel_class_alert_ok') 確定
        br

    h3 您已預約的課程如下:
    br
    transition(name='page-fade')
      b-alert(v-show='alert_message' :show='dismissCountDown' dismissible='' @dismiss-count-down='countDownChanged' :variant="successAlert? 'success' : 'danger'") {{ alert_message }}
    table.table.table-hover.table-clsapc-record
      table
        thead.thead-default
          tr
            th 上課時間
            th 課程型態
            th 操作
      .tableScrollBar
        table
          h4.text-center(v-if="todayClass.length === 0")
            b-badge(variant='danger') 當日尚未訂課
          tbody(v-else)
            tr(v-for='item in todayClass' :key='item.application_id')
              td {{ item.classTime }}
              td
                a.lnk-clsapc-1on1(v-if='item.enable1on1Link' href='javascript:;' @click='openUpdateBookingModal(item)')
                  span.fas.fa-file-upload.fa-lg
                  | {{ item.class_type_title }}
                template(v-else='')
                  span.txt-clsapc-type {{ item.class_type_title }}
              td
                span(v-if="(moment(item.class_time).diff(moment(currentTime), 'seconds')) < 900 || canNotCanlcelBook(item.class_type_id, item.cancel_status) " title='1.課程已結束 2.課程15分鐘後開始 3. 新身分需24小時前才可以取消') 無法取消
                a.red-text(v-else-if="(moment(item.class_time).diff(moment(currentTime), 'seconds')) > 900" href='javascript:;' @click='show_cancel_class_alert(item)') 取消預約
                span(v-else='') 處理中...
    br
    br
    b-row.text-center
</template>

<script type="text/javascript">
import moment from 'moment';
import { mapState } from 'vuex';
import LioshutanApi from '@/api';
import { chain } from 'lodash';

export default {
  name: 'TodayBooking',

  components: {
  },

  // i18n: recommendeDrecordsLang,

  props: {
    todayClassApplication: {
      type: Array,
      default: function() {
        return [];
      },
    },
    newRule: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      select_alert_message: '',
      alert_message: null,
      show_select_alert: false,
      bind_cancel_class: null,
      tomorrow: null,
      startTime: '',
      endTime: '',
      dismissSecs: 5,
      dismissCountDown: 0,
      successAlert: true,
      // now: null,
      moment: moment,
      cancel_class_options: [
        { text: '不喜歡本次教材', value: '38' },
        { text: '不喜歡本次顧問', value: '39' },
        { text: '網路 / 設備之技術問題', value: '40' },
        { text: '臨時有事', value: '41' },
        { text: '其他', value: '42' },
      ],
      cancel_class: {
        cancel_class_reason_value: null,
        cancel_class_reason_text: '',
        cancel_class_type: '',
      },

      // 假資料
      classPastApplication: {},
    };
  },

  computed: {
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
    todayClass() {
      const list = chain(this.todayClassApplication).cloneDeep().forEach((item) => {
        item.classTime = this.getClassTime(item.class_time);
        item.enable1on1Link = this.enable1on1Link(item);
      }).value();
      return list;
    },
  },

  watch: {},

  methods: {
    cancel_class_alert_cancel(){ // 取消課程取消
      this.alert_message = null;
      this.show_select_alert = false;
      this.cancel_class.cancel_class_type = '';
    },
    cancel_class_alert_ok() { // 取消課程確認
      this.alert_message = null;
      this.show_select_alert = false;
      this.dismissCountDown = this.dismissSecs; // alert計時
      this.cancel_book_class();
    },

    enable1on1Link(classApplication) {
      const settingLimit = moment(classApplication.class_time).subtract(72, 'hour');
      return classApplication.class_type_id === 1 && (moment(this.currentTime).isBefore(settingLimit) || classApplication.option) && (classApplication.class_deleted === 0 || classApplication.option);
    },

    getClassTime: function(time) {
      const timeArray = time.split(' ');
      return timeArray[1];
    },

    // openUpdateBookingModal(classApplication){
    //   let operation= (classApplication.option) ? 'view' : 'update';
    //   let currentClassApplication = {
    //     ca_id: classApplication.application_id,
    //     date: moment(classApplication.class_time).format('YYYY-MM-DD'),
    //     class_time: moment(classApplication.class_time).format('HH:mm'),
    //     option: classApplication.option,
    //     operation: operation,
    //     data_resource: 'application',
    //     todayBooking: true
    //   };
    //   this.setCurrentClassApplication(currentClassApplication);
    //   this.switchOpenUpdateBookingModal(true);
    // },

    async cancel_book_class(){
      const self = this;
      // const params = {
      //   ca_id: this.bind_cancel_class.application_id,
      //   dr: this.cancel_class.cancel_class_reason_value,
      //   drn: this.cancel_class.cancel_class_reason_text,
      // };
      if (this.check_cancel_class_time(this.bind_cancel_class.class_time)) {
        try {
          const classApllicationId = this.bind_cancel_class.application_id;
          const response = await LioshutanApi.bookingClass.cancelBookClass(classApllicationId);
          self.successAlert = true;
          self.alert_message = response.data.message;
          this.$emit('bookingClassChange', true);
        } catch (error) {
          self.successAlert = false;
          self.alert_message = error.response.data.message;
        }
      } else {
        self.successAlert = false;
        self.alert_message = '取消時間距離課程開始時間低於15分鐘，無法取消該課程。';
        // self.getTodayClass();
      }
    },
    // 檢查是否可以取消(15分鐘前才可以取消喔)
    check_cancel_class_time(class_time) {
      const diff_cancel_class_time = moment(class_time).diff(moment(this.currentTime), 'seconds');
      if (diff_cancel_class_time > 900) {
        return true;
      } else {
        return false;
      }
    },

    show_cancel_class_alert(item) {
      this.bind_cancel_class = null;
      this.bind_cancel_class = item; // 傳送item給 確定取消課程使用
      this.show_select_alert = true;
      this.select_alert_message = '<span style="color:black; font-size:16px;">< ' + item.class_type_title + ' ></span> <br>課程時間： <span style="color:#E74F4F;">' + item.class_time + '</span>';
      this.cancel_class.cancel_class_type = item.class_type_id;
    },

    // cancel_class_alert_ok() { // 取消課程確認
    //   this.alert_message = null;
    //   this.show_select_alert = false;
    //   this.dismissCountDown = this.dismissSecs; // alert計時
    //   this.cancel_book_class();
    // },

    // cancel_book_class(){
    //   const self = this;
    //   const cancel_book_params = this.set_cancel_book_params();
    //   if (this.check_cancel_class_time(this.bind_cancel_class.class_time)) {
    //     this.deleteClassApplication(cancel_book_params)
    //       .then(function(response) {
    //         self.successAlert = true;
    //         self.alert_message = response.data.message;
    //         self.getTodayClass();
    //         self.onSendAppierSdk(cancel_book_params.ca_id);
    //       }).catch((error) => {
    //         self.successAlert = false;
    //         self.alert_message = error.response.data.message;
    //       });
    //   } else {
    //     self.successAlert = false;
    //     self.alert_message = '取消時間距離課程開始時間低於15分鐘，無法取消該課程。';
    //     self.getTodayClass();
    //   }
    // },

    // onSendAppierSdk(ca_id){

    //   // let _self = this, parameter = {
    //   //   name : 'class_canceled',
    //   //   class_application_id: ca_id,
    //   // }
    //   // _self.queryAppierData(parameter)
    //   // .then((res)=>{
    //   //   let appier = res.data.data.appier;
    //   //   if(JSON.stringify(appier) !== '{}'){
    //   //     _self.sendAppierSdk(parameter);
    //   //   }
    //   // })
    // },

    // set_cancel_book_params() {
    //   if (this.cancel_class.cancel_class_reason_value === 40 || this.cancel_class.cancel_class_reason_value === 41) {
    //     this.cancel_class.cancel_class_reason_text = '';
    //   }
    //   const cancel_book_params = {
    //     ca_id: this.bind_cancel_class.application_id,
    //     dr: this.cancel_class.cancel_class_reason_value,
    //     drn: this.cancel_class.cancel_class_reason_text,
    //   };
    //   return cancel_book_params;
    // },

    // cancel_class_alert_cancel(){ //取消課程取消
    //   this.alert_message = null;
    //   this.show_select_alert = false;
    //   this.cancel_class.cancel_class_type = '';
    // },

    // check_cancel_class_time(class_time) { //檢查是否可以取消
    //   let self = this;
    //   let diff_cancel_class_time = moment(class_time).diff(moment(self.now), 'seconds');
    //   if (diff_cancel_class_time > 900) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },

    // getTodayClass(){
    //   this.startTime = this.today + ' 00:00:00';
    //   this.endTime = this.today + ' 23:59:59';
    //   this.queryClassPastApplication({cts:this.startTime, cte:this.endTime, per_page:100 ,st: 'specific_un_check_record'});
    // },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    canNotCanlcelBook(classType, cancelStatus){
      // 13:上傳教材
      const canNotCanlcelType = [3, 4, 8, 16];
      return canNotCanlcelType.includes(classType) || !cancelStatus;
    },
  },
};
</script>
<style lang="scss">
@import './todayBooking';
@import '@/styles/transition';
</style>
