var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('book-class', {
    on: {
      "timeChange": _vm.timeChange
    }
  }), _c('current-class-application', {
    attrs: {
      "show-filter": false,
      "showPage": _vm.bookClassConstants.SHOW_PAGE.BOOK_CLASS,
      "init": _vm.timeRange
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }