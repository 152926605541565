const applicationModalLang = {
  messages: {
    tw: {
      bookClass: '訂課',
      todayBooking: '當日課程',
      ok: 'OK',
      cancel: '取消',
      time: '時間',
      type: '類型',
      pleaseSelect: '請選擇',
      selectOneOnThree: '您選擇1-3人微班級，',
      selectOneOnOne: '您選擇 1 on 1 家教微課程，',
      deductOnePoint: '將會扣除您的1堂堂數!',
      deductThreePoint: '將會扣除您的3堂堂數!',
      privateBooking: '客製化課程須在',
      hour: '小時',
      booking: '預約',
      before: '前',
    },
    en: {
      bookClass: '',
      todayBooking: '',
      ok: 'OK',
      cancel: 'Cancel',
      time: 'Time',
      type: 'Type',
      pleaseSelect: '',
      selectOneOnOne: '',
      selectOneOnThree: '',
      deductOneOnOne: '',
      deductOneOnThree: '',
      privateBooking: '',
      hour: '',
      booking: '',
    },
  },
};

export {
  applicationModalLang
};
