<template lang="pug">
date-picker.select-date-and-time(
  v-model="thisDatetime"
  :minute-step="minuteStep"
  :placeholder="placeholder"
  :type="option.typeOfDateAndTime"
  :format="option.format"
  :value-type="option.returnValueFollowFormat"
  range
)
</template>

<script>
/* Doc
  Params:
   - datetime
    return Array [Start time, End time]
    format YYYY-MM-DD HH:mm
*/
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: 'DateAndTimePicker',

  components: {
    datePicker,
  },

  props: {
    datetime: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: {
      type: String,
      default() {
        return 'Select date and time range';
      },
    },
    minuteStep: {
      type: Number,
      default() {
        return 1;
      },
    },
  },

  data() {
    return {
      option: {
        format: 'YYYY-MM-DD HH:mm:ss',
        typeOfDateAndTime: 'datetime',
        returnValueFollowFormat: 'format',
      },
    };
  },

  computed: {
    thisDatetime: {
      get() {
        return this.datetime;
      },
      set(value) {
        this.$emit('update:datetime', value);
      },
    },
  },
};
</script>

<style scoped>
.select-date-and-time {
  width: 100%;
}
</style>
