export default {
  CLASSTYPE: {
    ONE_ON_ONE: 1, // 一對一訂課
    ONE_ON_TREE: 2, // 一對三訂課
    JUNIOR: 11, // Wuwow Junior訂課
  },
  APPLICATION_MODAL_SHOW_TYPE: {
    BOOKING: 'booking', // tab選到'訂課'
    TODAY_BOOKING: 'todayClass', // tab選到'當日課程'
  },
  PRIVATE_BOOKING_TYPE: {
    // 區分共用PB訂課模組的入口 依不同入口處理顯示及class
    NEW: 'new', // 預約訂課 新增課程
    UPDATE: 'update', // 目前訂課 更新課程資料
    CONSULTANT_SCHEDULE: 'consultantSchedule', // 於顧問行事曆裡訂課
  },
  MATERIAL_TYPE: {
    TOPIC: 1, // 教材類型 選擇適性分析
    UPLOAD: 2, // 教材類型 選擇自行上傳
  },
  GET_CLASS_RECORD_PARAMS_KEY: {
    STATUS: {
      CLASS_RECORD: 'class_record', // 訂課歷程
    },
    SHOW_DELETE: {
      YES: 0, // 只顯示沒被刪除的訂課
      NO: 1, // 顯示有被刪除的訂課
    },
    NEED_PAGINATOR: {
      YES: 1, // 要分頁(一次給10筆)
      NO: 0, // 不用分頁 會給全部（直接無視per_page給的參數）
    },
  },
  SCHEDULE_COLOR: { // 設定事件在行事曆上面的顏色
    YELLOW: '#ffc200', // 早上（9:00-12:00）=> 黃色
    RED: '#f05a37', // 下午（12:30-18:00）=> 紅色
    BLUE: '#0d6fd8', // 晚上（18:30-24:00）=> 藍色
    GRAY: '#959595', // 其他 => 灰色
  },
  POST_BOOKING_CLASS_PARAMS_KEY: {
    CLASS_APPLICATION: 'ca', // 要將參數放進ca的陣列裡傳出去
    CLASS_TIME: 'class_time',
    CLASS_TYPE: 'class_type',
    CONSULTANT_ID: 'consultant_id',
    MATERIAL_FILE: 'material_file',
    TYPE: 'ty',
  },
  GET_CONSULTANT_LIST_PARAMS_KEY: {
    STATUS: {
      VIP_APPLY: 'vip_apply_consultant',
    },
    SORT: {
      ENGLISH_NAME: 'english_name|asc',
    },
  },
  BOOKING_CLASS_RESPONSE: {
    STATUS: {
      SUCCESS: 1, // 預約課程成功
      FAIL: 2, // 預約課程失敗
    },
  },
  // 因為引用目前訂課的component有多頁 為區分是哪頁引用的 因應要求API時帶入不同參數
  SHOW_PAGE: {
    BOOK_CLASS: 'bookClass', // 預約訂課頁面
    BOOK_CLASS_RECORD: 'bookClassRecord', // 訂課紀錄頁面
  },
};
