<template lang="pug">
div
  //- 指定顧問input
  div(:class="[booking_type === bookClassConstants.PRIVATE_BOOKING_TYPE.UPDATE?'row':'']")
    b-form-group(:class="[booking_type === bookClassConstants.PRIVATE_BOOKING_TYPE.UPDATE?'col-sm-6':'']" :horizontal="booking_type !== bookClassConstants.PRIVATE_BOOKING_TYPE.UPDATE" :label-cols='3' breakpoint='sm' :label="$t('choice_consultant')" label-for='ddlConsultant' class="font-size-cus")
      multiselect#ddlConsultant(
        v-model='custom_application.consultant'
        track-by='teacher_id'
        :placeholder="$t('select_consultant_here')"
        :options='consultantList'
        label='english_name'
        :multiple='false'
        :clear-on-select='true'
        :close-on-select='true'
        :hide-selected='false'
        :preserve-search='true'
        :max-height='300'
        :disabled="booking_type=='private'"
      )
      p.annotation(v-if='custom_application.consultant && custom_application.consultant.vip_like_consultant === 0')
        | {{$t('dislike_consultant_alert')}}
  b-row.mt-3(v-if="newRule")
    //- TODO 指定教材加入元件 By Asa
    b-col.modal-label(cols="3") {{$t('designatedMaterial')}}
    b-col(cols="9")
      b-row.text-left
        b-col(cols="2")
          b-form-checkbox.upload-file-check-box(
            v-model="custom_application.isUploadMaterial"
            :value="true"
            :unchecked-value="false"
          )
        b-col(cols="10")
          b-form-file(
            v-if="custom_application.isUploadMaterial"
            v-model="custom_application.materialFile"
            :state="Boolean(custom_application.materialFile)"
            :placeholder="$t('uploadYourFile')"
            accept=".ppt, .pptx"
          )
          p.mt-1.text-success(v-if="custom_application.materialFile") 已選擇檔案： {{custom_application.materialFile.name}}
        b-col(cols="12")
          b-alert.text-left.mt-2(v-if="custom_application.isUploadMaterial" show variant="warning")
            span(v-html="$t('designatedMaterialNotice')")
            //- for 新約使用者提示，1對1提示訂課
            //- 已指定老師，且自備教材
            //- .mt-2(v-if="customizedClassroomRule.new_rule  && custom_application.isUploadMaterial")
            //-   p.notice 【一般課程-指定顧問、客製化課程】如「有」指定顧問或上傳自備教材，課程開始24小時前登入個人學習系統取消課程，不扣堂數；如於課程開始前「24小時內」預約指定顧問，則不得取消。

      //- b-row.mt-2
      //-   b-col
      //-     b-alert.text-left(v-if="custom_application.isUploadMaterial" show variant="warning")
      //-       span(v-html="$t('designatedMaterialNotice')")

  //- 備註 隱藏 (2022/06/29 trollo#740)
  //- b-form-group(:horizontal="booking_type !== bookClassConstants.PRIVATE_BOOKING_TYPE.UPDATE" :label-cols='3' breakpoint='sm' :label="$t('memo')" label-for='txtClassMemo')
  //-   b-form-textarea#txtClassMemo(v-model='custom_application.memo' type='text' rows='5' placeholder='如果您想安排更特定的教材主題，例如: 面試、發音，可在此處留言。如需較專業或學術的英文教材，因無法適用於其它學員，請自行上傳提供。若無符合您需求的教材，教務部門也會與您聯繫討論。')

  //- NOTE: 移置 applicationModal 元件中
  //- 《註：若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。》
  //- 《註：若選擇未開放時段之顧問，須待顧問回覆同意後才算訂課成功。》
  //- .mt-4
  //-   p.remark {{$t('appointConsultantRemark')}}
  //-   p.remark {{$t('bookingRemark')}}

  //- NOTE: 移置 applicationModal 元件中
  //- for 新約使用者提示，1對1提示訂課
  //- 已指定老師，但沒有自備教材
  //- .mt-2(v-if="customizedClassroomRule.new_rule && custom_application.consultant && !custom_application.isUploadMaterial")
  //-   p.notice 【一般課程-指定顧問、客製化課程】如「有」指定顧問或上傳自備教材，課程開始24小時前登入個人學習系統取消課程，不扣堂數；如於課程開始前「24小時內」預約指定顧問，則不得取消。
</template>

<script type="text/javascript">
import { privateBookingLang } from '@/components/vip/bookClass/privateBooking/lang';
import VueMultiselect from 'vue-multiselect';
import bookClassConstants from '@/constants/bookClass.js';
// import moment from 'moment';
// import { getDateWithTimeSecond } from '@/utils/moment';
// import { getDateWithTimeSecond } from '@/utils/moment';
// 1. 需要新身分去分辨是否打開
// 目前只是綁定

export default {
  name: 'PrivateBooking',

  components: {
    multiselect: VueMultiselect,
  },

  i18n: privateBookingLang,

  props: {
    // 區分共用PB訂課模組的入口 依不同入口處理顯示及class
    booking_type: {
      type: String,
      default: function() {
        return '';
      },
    },
    checkPrivateBookingTime: {
      type: Function,
      default: () => ({}),
    },
    topicList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    consultantList: {
      type: Array,
      default: function() {
        return [];
      },
    },
    classType: {
      type: Number,
      default: function() {
        return null;
      },
    },
    customizedClassroomRule: {
      type: Object,
      default: null,
    },
    classOptions: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },

  data() {
    return {
      custom_application: {
        consultant: null,
        materialFile: null,
        memo: '',
        selectMaterial: '',
        isUploadMaterial: false,
      },

      origin_consultant: null,

      material_type_option: [
        { value: 1, text: this.$t('interested_topic') },
        { value: 2, text: this.$t('upload') },
      ],

      materailList: [],

      fileErrorMessage: '',

      checkExamTopic: false,

      showSelectMaterail: true,

      bookClassConstants: bookClassConstants,
    };
  },

  computed: {
    newRule() {
      // 判斷是否要顯示教材選項
      if (this.customizedClassroomRule.new_rule) { // 在新規則底下需要
        if (this.checkPrivateBookingTime()) { // 72小時候可以顯示
          return true;
        }
        return false; // 72內不顯示
      }
      return true; // 這邊預設 true 讓他父元件直接跑舊規則
    },
  },

  watch: {
    custom_application: {
      handler(value){
        // 若取消指定教材清空上傳檔案
        if (!value.isUploadMaterial){
          this.custom_application.materialFile = null;
        }
        this.$emit('custom-application', value);
      },
      deep: true,
    },
    consultantList(){
      this.custom_application.consultant = null;
    },
  },
  // methods: {
  //   checkPrivateBookingTime(){
  //     // 超過72小時才可以訂客製化訂課
  //     const settingLimit = moment(getDateWithTimeSecond(this.bookingDate.bookingDateValue + ' ' + this.filter.class_time + ':00')).subtract(72, 'hour');
  //     return (moment().isBefore(settingLimit));
  //   },
  // },

  mounted() {
    this.custom_application.isUploadMaterial = this.classOptions.isUploadMaterial;
    this.custom_application.materialFile = this.classOptions.material_file;
    this.custom_application.consultant = this.classOptions.consultant;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.remark{
  color: red;
  margin-bottom: 3px;
  font-size: 13px;
  text-align: center;
  line-height: 16px;
}
.notice{
  color: red;
  text-align: start;
}
.upload-file-check-box{
  left: -30px
}

.font-size-cus {
  font-size: 18px;
  font-weight: Bold;
}

.modal-label {
  font-size: 18px;
  font-weight: Bold;
}
</style>
