<template lang="pug">
//-  訂課行事曆點擊日期出現的modal
b-modal(id="classApplicationModal" size="lg" class="application-modal" centered  @hidden='cancelBooking' no-close-on-backdrop='' hide-footer)
    b-container.bv-example-row
      b-row.text-center.application-modal-title
        b-col
          p.title-week {{getWeekDay}}
          p.title-date {{bookingDate.bookingTitle}}
      br
      //- 切分頁：訂課/當日課程
      b-form-group
        b-nav.book-type(fill='')
          b-nav-item(:class="[showType === bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.BOOKING? 'buttonSelected' : 'buttonCancel']" @click="showType=bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.BOOKING") {{ $t('bookClass') }}
          b-nav-item(:class="[showType === bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.TODAY_BOOKING? 'buttonSelected' : 'buttonCancel']" @click="showType=bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.TODAY_BOOKING ") {{ $t('todayBooking') }}
      br

      //- tab：訂課 => 顯示訂課選項
      div(v-show="showType === bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.BOOKING")
        //- 步驟列
        .step-bar
          .step(v-for="(item, index) in step.enum", :class="{active: step.active == index + 1}")
            .step-circle {{ index+ 1}}
            p {{ item }}

        transition(name="fade", mode="out-in")
          //- 訂課步驟 1
          section(v-if="step.active == 1", key="1")
            //- 時間選項
            b-form-group
              .panel-clsapc-input
                .input-group
                  .input-group-prepend
                    span.input-group-text {{ $t('time') }}
                  b-form-select(required='' name='time' v-validate="'required'" v-model='filter.time' :options='class_time' @change='onChangeFilter')
                  b-form-select(required='' name='class_time' v-validate="'required'" v-model='filter.class_time' :options='timelistOptions' @change='onChangeFilter')
                    template(slot='first')
                      option(:value='null' disabled='disabled') {{ $t('pleaseSelect') }}{{ $t('time') }}

                  br

            div(v-for='(message, index) in alert_messages' :key='index')
              b-alert.book-res(v-show='message.text' :show='true' :variant="message.alert? 'success' : 'danger'") {{index + 1}}. {{ message.text }}
            //- 警告 如欲指定顧問，或預約客製化課程(自備教材)，請於課程72小時前預約
            div(v-show='enablePrivateConsultantBooking')
              b-alert.pb-alert.text-center(show)
                | 如欲指定顧問，或預約客製化課程(自備教材)
                br
                |，請於課程
                span.alertText 72{{ $t('hour') }}
                | {{ $t('before') }}{{ $t('booking') }}

            //- PB訂課選項
            div(v-show='enablePrivateBooking')
              private-booking(data_resource='application'
                :checkPrivateBookingTime='checkPrivateBookingTime'
                :topicList='topicList'
                :consultantList='consultantList'
                :classType='filter.class_type.value'
                :classOptions='classOptions'
                @custom-application='oneOnOneData'
                :key='filter.class_type.value'
                :customized-classroom-rule="customizedClassroomRule"
                :booking_type="'new'")

          //- 訂課步驟 2
          section(v-if="step.active == 2", key="2")
            b-list-group
                b-list-group-item 日期：{{ formatDate(bookingDate.bookingDateValue) }}
                b-list-group-item 時段：{{ findOption(class_time, 'value' , filter.time, 'text')}}
                b-list-group-item 時間：{{ formatTime(filter.class_time) }}
                b-list-group-item 課程類型：{{findOption(class_type, 'value.value' , filter.class_type.value, 'text')}}
                b-list-group-item 指定顧問：{{classOptions.consultant ? classOptions.consultant.english_name: '--'}}
                b-list-group-item(v-if="classOptions.isUploadMaterial") 指定自備教材：{{classOptions.material_file ? classOptions.material_file.name: '--'}}

            div.mt-2(v-for='(message, index) in alert_messages' :key='index')
              b-alert.book-res(v-show='message.text' :show='true' :variant="message.alert? 'success' : 'danger'") {{index + 1}}. {{ message.text }}

            b-alert.mt-3(variant="warning",show)
              p 提醒您 :
              ol
                li 若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。
                //- li 若選擇未開放時段之顧問，須待顧問回覆同意後才算訂課成功
                //- for 舊合約使用者，有上傳檔案
                li(v-if="!customizedClassroomRule.new_rule && classOptions.material_file") 【客製化課程】：上傳自備教材並可同時指定顧問，需於72小時前預約，每次扣除課堂數為兩堂，預約成功後不得取消課程。
                //- for 新合約使用者，客製化課程(指定老師、上傳檔案)
                li.text-danger.time-alert(v-if="customizedClassroomRule.new_rule && (classOptions.consultant || classOptions.material_file)") {{ timeAlert() }}

            //- 從 private-booking 元件移植
            //- .mt-2(v-if='enablePrivateBooking')
            //-     p.remark 《註：若指定之顧問因不可抗因素無法授課，教務團隊將為你更換顧問，並即時通知您。
            //-     p.remark 《註：若選擇未開放時段之顧問，須待顧問回覆同意後才算訂課成功。》

            //- .mt-2(v-if="customizedClassroomRule.new_rule && (classOptions.teacher_id || classOptions.material_file)")
            //-       p.notice 【一般課程-指定顧問、客製化課程】如「有」指定顧問或上傳自備教材，課程開始24小時前登入個人學習系統取消課程，不扣堂數；如於課程開始前「24小時內」預約指定顧問，則不得取消。

      //- tab=>顯示今日課程
      div(v-if="showType === bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.TODAY_BOOKING")
        today-booking(:todayClassApplication='bookingDate.classApllication' :new-rule="customizedClassroomRule.new_rule" @bookingClassChange='bookingClassChange')
    //- modal footer(送出訂課按鈕)
    div.modal-footer.mt-3(v-show='showType !== bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.TODAY_BOOKING')
      //- b-button.ok-button.mt-3(variant="success" block @click="onClassSubmit" :disabled='filter.class_time === null')
      //-   font-awesome-icon(:icon="['fa', 'check']" size="2x")

      //- 打勾圖示，確認按鈕
      //- b-button.ok-button.mt-3(variant="success" block @click="$bvModal.show('confirmModal')" :disabled='filter.class_time === null')
      //-   font-awesome-icon(:icon="['fa', 'check']" size="2x")

      //- 取消按鈕先放著
      //- b-button.ok-button.mt-3(variant="danger" block @click="$bvModal.hide('classApplocationModal')")
      //-   font-awesome-icon(:icon="['fa', 'turn-down-left']" size="2x")

      //- 第一步確認
      //- b-button(v-if="step.active == 1",variant="primary" @click="step.active = 2" :disabled='filter.class_time === null', key="next" block) 下一步 / 確認課程資訊
      b-button(v-if="step.active == 1",variant="primary" @click="step.active = 2" :disabled='!enableSubmit', key="next" block) 下一步 / 確認課程資訊

      //- 第二步才送出
      b-button(v-if="step.active == 2 && !alert_messages.length",variant="primary" @click="step.active = 1" key="previous" block) 上一步
      b-button(v-if="step.active == 2 && !alert_messages.length",variant="success" @click="onClassSubmit()" key="success" block) 確認送出訂課

    //- 確認送出 modal
    //- b-modal(id="confirmModal",hide-footer, centered)
    //-   h2 請確認是否送出訂課申請
    //-   .modal-footer
    //-     b-button(variant="danger", @click="$bvModal.hide('confirmModal');$bvModal.hide('classApplicationModal')") 取消
    //-     b-button(variant="success", @click="$bvModal.hide('confirmModal');onClassSubmit()") 送出
</template>

<script type="text/javascript">
import { omit, forEach, mapKeys, snakeCase, get } from 'lodash';
import { applicationModalLang } from '@/components/vip/bookClass/applicationModal/lang';
import moment from 'moment';
import { getDateWithTimeSecond } from '@/utils/moment';
import { getPlatformCanTeachConsultants, getPlatformStudentLevelConsultant } from '@/utils/consultant';
import todayBooking from '@/components/vip/bookClass/todayBooking/index.vue';
import privateBooking from '@/components/vip/bookClass/privateBooking/index.vue';
import bookClassConstants from '@/constants/bookClass.js';
import platformConstants from '@/constants/platform.js';
import classroomConstants from '@/constants/classroom.js';
import lioshutanApi from '@/api';
import { mapState } from 'vuex';

const currentPalaform = platformConstants.WUWOW_JUNIOR;
export default {
  name: 'BookingClassApplicationModal',

  components: {
    todayBooking,
    privateBooking,
  },

  i18n: applicationModalLang,

  props: {
    bookingDate: {
      type: Object,
      default: function() {
        return false;
      },
    },
  },

  data() {
    return {
      showType: bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.BOOKING,
      now: null,

      // 訂課步驟
      step: {
        active: 1,
        enum: ['選擇課程設定', '再次確認課程'],
      },

      filter: {
        id: '',
        date: '',
        time: null,
        class_time: null,
        class_type: { value: classroomConstants.BOOK_CLASS.CLASS_TYPE_NORMAL, label: 'Wuwow Junior', ty: 'junior_normal' },
      },

      class_time: [
        { text: '請選擇時段', value: null, disabled: true },
        { text: '早上', value: 'morning' },
        { text: '下午', value: 'afternoon' },
        { text: '晚上', value: 'evening' },
      ],

      timelist: {},
      // TODO: 要判斷角色（role）可不可以定一對一/考試
      class_type: [
        { text: '請選擇課程類型', value: { value: 0, label: '請選擇課程類型' }},
        { text: '1 on 1 家教', value: { value: 1, label: '1 on 1 家教', ty: 'normal' }},
        { text: '1-3人微班級', value: { value: 2, label: '1-3人微班級', ty: 'normal' }},
        { text: '1 on 1 Junior家教班', value: { value: 11, label: '1 on 1 Junior 家教班', ty: 'junior_normal' }},
        { text: '1 on 1 Junior自備教材家教班', value: { value: 13, label: '1 on 1 Junior 自備教材家教班', ty: 'junior__specify_material' }},
        { text: '考試', value: { value: 33, label: '考試', ty: 'normal' }},
      ],
      classOptions: {
        // teacher_id: '',
        // 指定老師改由賦值整個選項物件，供時間軸判斷及顯示
        consultant: null,
        memo: '',
        material_file: null,
        isUploadMaterial: false,
      },
      alert_messages: [],
      successAlert: true,
      bookClassConstants: bookClassConstants,
      topicList: [],
      consultantList: [],
      // enableSubmit: false,

      teachConsultants: [],
      customizedClassroomRule: {},
    };
  },

  computed: {
    timelistOptions() {
      return this.setTimelistOptions();
    },

    enablePrivateConsultantBooking() {
      // 這邊也要增加規則 72小時舊規則提示
      if (this.customizedClassroomRule.new_rule) {
        // 會產生新的規則提示文字需要待"禮書"確認
        // 新規則  下一個時段可以指定顧問，教材72小時候規則照舊
        // return this.filter.class_time !== null;
        this.checkPrivateBookingTime();
        return false;
      }
      // 舊規則 72 小時 所產生提示文字
      return !this.checkPrivateBookingTime() && this.filter.class_time !== null;
    },

    // 從 private booking 移植，要根據新舊合約判斷
    enablePrivateMaterial() {
      if (this.customizedClassroomRule.new_rule) {
        // 72小時後，才顯示
        if (this.checkPrivateBookingTime()) {
          return true;
        }
        return false;
      }
      return true;
    },

    enablePrivateBooking() {
      if (this.customizedClassroomRule.new_rule) {
        // 新規則 下一個時段可以指定顧問，教材72小時候規則照舊
        return this.filter.class_time !== null;
      }
      return this.checkPrivateBookingTime() && this.filter.class_time !== null;
    },

    getWeekDay(){
      return moment(this.bookingDate.bookingDateValue).format('dddd');
    },

    enableSubmit(){
      // 有填時間且超過72小時 =true
      // return this.checkPrivateBookingTime() && this.checkEnableSubmit();

      // 規則改為檢查時間、自備教材
      let rule = true;
      if (!this.filter.class_time) {
        rule = false;
      }
      if (this.classOptions.isUploadMaterial === true && !this.classOptions.material_file) {
        rule = false;
      }
      return rule;
    },
    ...mapState({
      currentTime: state => state.common.currentTime,
    }),
  },

  watch: {
    bookingDate: {
      async handler(newValue) {
        if (newValue.bookingDateValue !== ''){
          this.getSpecificTimeSlot(newValue.bookingDateValue);
        }
      },
      deep: true,
    },
  },

  async created() {
    this.teachConsultants = await getPlatformCanTeachConsultants(currentPalaform);
    this.getStudentPorfile();
  },

  methods: {
    getStudentPorfile(){
      lioshutanApi.vip.getBaseProfile().then((result) => {
        this.customizedClassroomRule = result.data.customized_classroom_rule;
        // 測試切換 新舊學員
        // this.customizedClassroomRule.new_rule = false;
      });
    },
    // 取得特定日期可選時段與時間
    async getSpecificTimeSlot(date){
      this.class_time = [{ text: '請選擇時段', value: null, disabled: true }];
      this.timelist = {};
      let timeslots = await this.getClassTimeslots(currentPalaform, date);
      // 這邊直接判斷是否顯示時段
      const nowSection = this.getCurrentTimeSection(date);
      timeslots = omit(timeslots, nowSection);
      forEach(timeslots, (value, key) => {
        this.getTimeSection(key);
        const timeLists = value.map((timeInfo) => {
          return timeInfo.time;
        });
        this.timelist[key] = timeLists;
      });
    },

    getTimeSection(timeSection){
      switch (timeSection){
        case 'morning':
          this.class_time.push({ text: '早上', value: timeSection });
          break;
        case 'afternoon':
          this.class_time.push({ text: '下午', value: timeSection });
          break;
        case 'evening':
          this.class_time.push({ text: '晚上', value: timeSection });
          break;
        default:
          break;
      }
    },
    getCurrentTimeSection(targetTime) { // 取得當下時段
      const now = moment(this.currentTime);
      const nowStr = now.format('YYYY-MM-DD');
      if (!moment(targetTime).isSame(nowStr)) {
        return '';
      }
      // 設定早上時間範圍為 00:00 ~ 11:59
      const morningStart = moment('00:00:00', 'HH:mm:ss');
      const morningEnd = moment('11:59:59', 'HH:mm:ss');

      // 設定下午時間範圍為 12:00 ~ 17:59
      const afternoonStart = moment('12:00:00', 'HH:mm:ss');
      const afternoonEnd = moment('17:59:59', 'HH:mm:ss');

      // 設定晚上時間範圍為 18:00 ~ 23:59
      const eveningStart = moment('18:00:00', 'HH:mm:ss');
      const eveningEnd = moment('23:59:59', 'HH:mm:ss');
      // 判斷現在時間在哪個時間範圍
      switch (true) {
        case now.isBetween(morningStart, morningEnd):
          return 'morning';
        case now.isBetween(afternoonStart, afternoonEnd):
          return 'afternoon';
        case now.isBetween(eveningStart, eveningEnd):
          return 'evening';
      }
    },

    async getClassTimeslots(platform = currentPalaform, date){
      const params = {
        days: [date],
        platform: platform,
        splitSection: true, // 時間照區段切分(morngin)
      };
      const result = await lioshutanApi.bookingClass.getClassTimeslots(params);
      return result.data.data.timeslots[date];
    },

    // TODO: JR的class_type 目前只會有一對一 funtion裡的規則要刪掉
    cancelBooking(){
      this.setDefaultFilter();
      // 一併復原 private booking 裡面選項
      this.resetSelectOption();
      this.showType = bookClassConstants.APPLICATION_MODAL_SHOW_TYPE.BOOKING;
      this.alert_messages.length = 0;
      // 步驟列退回第一步
      this.step.active = 1;
    },

    setTimelistOptions(){
      if (this.filter.time !== null) {
        // 選擇完時段後 時間會變成對應的option
        this.filter.class_time = null;
        return this.timelist[this.filter.time];
      } else {
        return [];
      }
    },

    checkPrivateBookingTime(){
      // 超過72小時才可以訂客製化訂課
      const settingLimit = moment(getDateWithTimeSecond(this.bookingDate.bookingDateValue + ' ' + this.filter.class_time + ':00')).subtract(72, 'hour');
      return (moment().isBefore(settingLimit));
    },

    timeAlert() {
      const nextDay = moment().add(1, 'day');
      const bookingAt = moment(`${this.bookingDate.bookingDateValue} ${this.filter.class_time}:00`);
      console.warn('isBefore', moment(bookingAt).isBefore(nextDay));

      // 判斷定課時間，是否於一天之內
      switch (moment(bookingAt).isBefore(nextDay)) {
        // 一天之內
        case true:
          return '此課程為24小時內之一對一指定課程，預約後不可取消喔！';
        // 一天之後
        default:
          return '此課程為24小時後之一對一指定課程，預約後如需取消，請於課前24小時前取消！';
      }
    },

    oneOnOneData(option){ // 空欄位不需要傳至後端
      // this.disableSubmit = this.canSubmit(option);

      // lodash mapKeys
      this.classOptions = mapKeys(
        option,
        (value, key) => {
          if (key === 'isUploadMaterial') {
            return key;
          }
          return snakeCase(key);
        });

      // if (option.consultant) {
      //   this.classOptions.teacher_id = option.consultant.teacher_id;
      // }
      // if (option.isUploadMaterial) {
      //   this.classOptions.isUploadMaterial = option.isUploadMaterial;
      //   this.classOptions.material_file = option.materialFile;
      // }
      // this.classOptions.memo = option.memo;
    },

    // 按下ok 送出訂課
    async onClassSubmit(){
      const self = this;
      // this.filter.date = this.bookingDate.bookingDateValue; //帶入選擇日期
      const params = {
        ca: [],
      };

      const classTime = getDateWithTimeSecond(this.bookingDate.bookingDateValue + ' ' + this.filter.class_time + ':00');

      // 判斷是否指定老師
      let assignTeacherId = '';
      if (this.classOptions.consultant && this.classOptions.consultant.teacher_id) {
        assignTeacherId = this.classOptions.consultant.teacher_id;
      }

      params.ca.push({
        class_time: classTime,
        class_type: this.filter.class_type.value,
        teacher_id: assignTeacherId,
        material_file: this.classOptions.material_file,
      });
      const form = new FormData();
      for (let i = 0; i < params.ca.length; i++) {
        form.append(bookClassConstants.POST_BOOKING_CLASS_PARAMS_KEY.CLASS_APPLICATION + '[' + i + '][' + bookClassConstants.POST_BOOKING_CLASS_PARAMS_KEY.CLASS_TIME + ']', params.ca[i].class_time);
        // 預先判斷避免讀取到 undefined
        if (assignTeacherId !== '') {
          form.append(bookClassConstants.POST_BOOKING_CLASS_PARAMS_KEY.CLASS_APPLICATION + '[' + i + '][option][' + bookClassConstants.POST_BOOKING_CLASS_PARAMS_KEY.CONSULTANT_ID + ']', params.ca[i].teacher_id);
        }
        if (this.classOptions.material_file !== null && this.classOptions.material_file !== undefined){
          form.append(bookClassConstants.POST_BOOKING_CLASS_PARAMS_KEY.CLASS_APPLICATION + '[' + i + '][option][' + bookClassConstants.POST_BOOKING_CLASS_PARAMS_KEY.MATERIAL_FILE + ']', params.ca[i].material_file);
          // 若有指定教材 class_type更改為 13
          params.ca[i].class_type = classroomConstants.BOOK_CLASS.CLASS_TYPE_SPECIFY_MATERIAL;
        }
        form.append(bookClassConstants.POST_BOOKING_CLASS_PARAMS_KEY.CLASS_APPLICATION + '[' + i + '][' + bookClassConstants.POST_BOOKING_CLASS_PARAMS_KEY.CLASS_TYPE + ']', params.ca[i].class_type);
        form.append(bookClassConstants.POST_BOOKING_CLASS_PARAMS_KEY.TYPE, this.filter.class_type.ty);
      }

      try {
        const res = await lioshutanApi.bookingClass.bookingClass(form);
        // 設置警告提示
        for (let i = 0; i < res.length; i++) {
          // NOTE:訂課步驟 2 會顯示訂課資訊，關閉後再清空設定
          // self.classOptions = {
          //   teacher_id: '',
          //   memo: '',
          //   material_file: null,
          // };
          const alert_message = {
            alert: true,
            text: '',
          };
          if (res[i].status === bookClassConstants.BOOKING_CLASS_RESPONSE.STATUS.SUCCESS) {
            // 訂課成功
            alert_message.alert = true;
            this.$emit('bookingClassChange', true);
            // NOTE:訂課步驟 2 會顯示訂課資訊，關閉後再清空設定
            // this.setDefaultFilter();
          } else {
            // 訂課失敗
            alert_message.alert = false;
          }
          alert_message.text = res[i].message;
          self.alert_messages.push(alert_message);
        }
      } catch (error) {
        console.error(error);
      }
    },

    // 切換選項時
    onChangeFilter(){
      // 是否呼叫適性及顧問選項的api
      // 選完時間類型 且 類型不等於一對三 呼叫 顧問的列表
      const ONE_ON_TREE = bookClassConstants.CLASSTYPE.ONE_ON_TREE;
      const ONE_ON_ONE = bookClassConstants.CLASSTYPE.ONE_ON_ONE;
      if (this.filter.class_type.value !== 0 &&
        this.filter.class_time &&
        this.filter.class_type.value !== ONE_ON_TREE) {
        this.getConsultantList();
        // 只有類型選一對一才需要適性的選項
        if (this.filter.class_type.value === ONE_ON_ONE) {
          this.getTopicList();
        }
      }
      // 送出按鈕是否隱藏
      // this.enableSubmit = this.checkEnableSubmit();
      // 清空選項
      this.resetSelectOption();
    },
    async getTopicList(){
      this.topicList = await lioshutanApi.bookingClass.getTopicList();
    },
    async getConsultantList(){
      // 選擇時間後才會去逮這個 Fn
      const classTime = getDateWithTimeSecond(this.bookingDate.bookingDateValue + ' ' + this.filter.class_time + ':00');
      // TODO: 待之後ＪＲ取得顧問列表的ＡＰＩ有調整 這裡帶的參數也要調整
      // const params = {
      //   cts: classTime,
      //   st: bookClassConstants.GET_CONSULTANT_LIST_PARAMS_KEY.STATUS.VIP_APPLY,
      //   sort: bookClassConstants.GET_CONSULTANT_LIST_PARAMS_KEY.SORT.ENGLISH_NAME,
      // };
      // this.consultantList = await lioshutanApi.bookingClass.getConsultantList(params);
      // 取得相對應平台學員等級範圍內顧問
      this.consultantList = await getPlatformStudentLevelConsultant(currentPalaform, classTime, this.teachConsultants);
    },
    checkEnableSubmit(){
      // 選完時間及類型
      if ((this.filter.time !== -1 && this.filter.class_time) && (this.filter.class_type.value !== 0)) {
        // 如果類型選JUNIOR 老師為必填
        if (this.filter.class_type.value === bookClassConstants.CLASSTYPE.JUNIOR && this.classOptions && this.classOptions.teacher_id) {
          return true;
        } else if (this.filter.class_type.value !== bookClassConstants.CLASSTYPE.JUNIOR) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    setDefaultFilter(){
      this.filter = {
        id: '',
        date: '',
        time: null,
        class_time: null,
        class_type: { value: 11, label: 'Wuwow Junior', ty: 'junior_normal' },
      };
    },
    resetSelectOption(){
      // this.classOptions = {
      //   teacher_id: '',
      //   memo: '',
      //   material_file: null,
      // };
      this.classOptions = {
        consultant: null,
        materialFile: null,
        memo: '',
        selectMaterial: '',
        isUploadMaterial: false,
      };
    },
    bookingClassChange(){
      this.$emit('bookingClassChange', true);
    },

    // 尋找選項
    findOption(sourceData, path, value, targetProp) {
      if (!value) {
        return '--';
      }
      const target = sourceData.find((item) => {
        // lodash get
        const itemProp = get(item, path);
        return itemProp === value;
      });

      if (targetProp) {
        return get(target, targetProp);
      }
      return target;
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    formatTime(time) {
      const today = moment().format('YYYY-MM-DD');
      return moment(`${today} ${time}`).format('HH:mm:ss');
    },
  },
};
</script>
<style lang="scss" >
@import '@/components/vip/bookClass/applicationModal/applicationModal';
@import '@/styles/transition';
</style>
<style lang="scss" scoped>
.application-modal-title {
  margin-top: -50px !important;
}
.pb-alert {
  background-color: #DDE5FA;
}

.step-bar{
  margin: 5px auto;
  width: 66%;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  position: relative;

  --circle-width: 40px;
  &::before{
    width: calc(100% - 3 * var(--circle-width)) ;
    height: 1px;
    position: absolute;
    top: 35%;
    left: calc(1.5 * var(--circle-width));
    content: '';
    background-color: lightgray;
  }

  .step{
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: #6c757d;
      margin-top: 5px;
      margin-bottom: 0px;
    }
    &.active{
      p{
      color: #82c1ea;
      }
      .step-circle{
        background-color: #82c1ea;
        border-color: #82c1ea;
      }
    }

  }
  .step-circle{
    flex-shrink: 0;
    border-radius: 50%;
    width: var(--circle-width);
    height: var(--circle-width);

    color: #fff;

    background-color: #a4b7c1;
    border-color: #a4b7c1;

    display: flex;
    justify-content: center;
    align-items: center;

    transition: background-color 0.35s ease-out;
    &.active{
    background-color: #82c1ea;
    border-color: #82c1ea;
    }
  }
}

// 修改 b-modal 排版
.modal-footer{
  flex-wrap: nowrap;
}

.remark{
  color: red;
  margin-bottom: 3px;
  font-size: 13px;
  text-align: center;
  line-height: 16px;
}
.notice{
  margin-bottom: 0px;
  color: red;
  text-align: start;
}

.time-alert{
  font-size: 18px;
}
</style>
