import { intersectionBy } from 'lodash';
import lioshutanApi from '@/api';
import platformConstants from '@/constants/platform';
const currentPalaform = platformConstants.WUWOW_JUNIOR;

/**
  * 取得平台學員相對等級可授課所有老師清單
  * @param platform: 平台
  * @param studentLevel: 學員等級
  * @returns {Promise<Object>} 顯示平台所有可授課老師 [{ teacher_id: id}, ...];
  * @descreption 用意先取得平台可授課老師 再去比對可教等級範圍內顧問
*/
export async function getPlatformCanTeachConsultants(platform = currentPalaform) {
  const perPage = 999;
  const params = {
    platform: platform,
    perPage: perPage,
  };
  const platformName = platform === 1 ? 'wuwow' : 'wuwow_junior';
  const baseProfile = await lioshutanApi.vip.getBaseProfile();
  const studentLevel = baseProfile.data.profile_base.level;
  const result = await lioshutanApi.personnel.queryPlatformConsultantCanTeach(params);
  const allowLevel = result.data.data.teach.filter((teacher) => {
    return (teacher.teach_level_range[platformName].min_level <= studentLevel && teacher.teach_level_range[platformName].max_level >= studentLevel);
  });
  const consultants = allowLevel.map((teacher) => {
    return { teacher_id: teacher.profile.hb_consultant_id, english_name: teacher.profile.english_name };
  });
  return (consultants);
}

/**
  * 取得平台特定時段學員等級內符合資格上課顧問
  * @param platform: 平台
  * @param bookingTime: 上課時間 YYYY-MM-DD HH:mm:ss
  * @returns {Promise<Object>} 顯示平台特定時段可授課老師
  * @descreption 比對平台可上課顧問選出特定時間可上課顧問
*/
export async function getPlatformStudentLevelConsultant(platform = currentPalaform, bookingTime, studentLevelConsultants) {
  const params = {
    platform: platform,
    bookingTime: bookingTime,
  };
  const result = await lioshutanApi.personnel.querySpecificConsultantTimeslot(params);
  const platformStudentLevelConsultant = intersectionBy(studentLevelConsultants, result.data.data.timeslots, 'teacher_id');
  return platformStudentLevelConsultant;
}
